import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GameContext } from "../../game/store";
import { GameStateEnum } from "../../game/gameEnums.mjs";
import Button from "../utils/Button";
import MainMenu from "./mainmenu/MainMenu";
import CustomWords from "./panelcategories/CustomWords";
import OtherChannelEmotes from "./panelcategories/OtherChannelEmotes";
import Settings from "./Settings";
import Header from "./Header";
import useShowPanel from "../utils/useShowPanel";
import ChatWords from "./panelcategories/ChatWords";
import ScoreBoard from "./ScoreBoard";

const SidePanel = () => {
  const gameContext = React.useContext(GameContext);
  const [currentPanel, setCurrentPanel] = React.useState("MainMenu");
  const [transitionState, setTransitionState] = React.useState(true);
  const [reverseTransition, setReverseTransition] = React.useState(false);
  const [hideMainPanel, setHideMainPanel] = React.useState(false);
  const panelPayload = React.useRef();
  const showPanel = useShowPanel(true);

  const setPanel = (panelName, payload) => {
    setTransitionState(false);
    setTimeout(() => {
      panelPayload.current = payload != null ? payload : null;
      setCurrentPanel(panelName);
      setTransitionState(true);
      setReverseTransition((reverseTransition) => !reverseTransition);
    }, 200);
  };

  return React.useMemo(() => {
    return (
      <CSSTransition
        in={showPanel}
        classNames={"slide"}
        timeout={600}
        onExited={() => {
          setHideMainPanel(true);
        }}
        onEnter={() => {
          setHideMainPanel(false);
        }}
      >
        <div
          className={`md:flex w-full md:w-120 ${
            (showPanel || !hideMainPanel) && "z-30"
          } ${
            gameContext.state.gameState >= GameStateEnum.RoundStart &&
            "md:absolute"
          }`}
        >
          <div className={`w-full flex-none ${hideMainPanel && "hidden z-bg"}`}>
            <Header />
            <div
              style={{ height: "55rem" }}
              className="bg-DTC_DarkBlue border_side overflow-x-hidden"
            >
              <CSSTransition
                in={transitionState}
                classNames={reverseTransition ? "reverseFade" : "fade"}
                timeout={200}
              >
                <div className="h-full">
                  <div
                    className={
                      currentPanel === "MainMenu" ? "block h-full" : "hidden"
                    }
                  >
                    <MainMenu setPanel={setPanel} />
                  </div>
                  <div
                    className={
                      currentPanel === "Settings" ? "block h-full" : "hidden"
                    }
                  >
                    <Settings setPanel={setPanel} />
                  </div>
                  <div
                    className={
                      currentPanel === "ScoreBoard" ? "block h-full" : "hidden"
                    }
                  >
                    <ScoreBoard setPanel={setPanel} />
                  </div>
                  <div>
                    {currentPanel === "CustomWords" && (
                      <CustomWords
                        setPanel={setPanel}
                        payload={panelPayload.current}
                      />
                    )}
                  </div>
                  <div>
                    {currentPanel === "ChatWords" && (
                      <ChatWords
                        setPanel={setPanel}
                        payload={panelPayload.current}
                      />
                    )}
                  </div>
                  <div
                    className={
                      currentPanel === "OtherChannelEmotes"
                        ? "block h-full"
                        : "hidden"
                    }
                  >
                    <OtherChannelEmotes
                      setPanel={setPanel}
                      payload={panelPayload.current}
                    />
                  </div>
                </div>
              </CSSTransition>
            </div>
          </div>
          <div className="mt-52 hidden md:block border_side h-12 hover:bg-DTC_Yellow hover:text-black bg-DTC_DarkBlue">
            <Button
              label="Toggle Side Panel"
              OnClick={() => {
                gameContext.dispatch({
                  type: "UpdateShowSidePanel",
                  payload: {
                    showSidePanel: !gameContext.state.showSidePanel,
                  },
                });
              }}
            >
              <div className="px-3 w-full text-2xl h-12 flex justify-center items-center">
                {gameContext.state.showSidePanel ? (
                  <FontAwesomeIcon icon={faArrowLeft} />
                ) : (
                  <FontAwesomeIcon icon={faArrowRight} />
                )}
              </div>
            </Button>
          </div>
        </div>
      </CSSTransition>
    );
  }, [
    showPanel,
    currentPanel,
    transitionState,
    reverseTransition,
    hideMainPanel,
  ]);
};

export default SidePanel;

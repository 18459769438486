import * as React from "react";
import PropTypes from "prop-types";
import useChat from "../../utils/useChat";
import { WordRegEx } from "../../../utils/browser_utils";
import { GameContext } from "../../../game/store";
import CheckBox from "../../utils/CheckBox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";

const settings = {
  maxWords: 400,
  maxChars: 45,
  commandPrefix: "!add",
};

const ChatWords = ({ setPanel, payload }) => {
  const {
    state: { user },
  } = React.useContext(GameContext);
  const { msgState } = useChat();
  const [wordList, setWordList] = React.useState([]);

  const prefixReg = new RegExp(`^${settings.commandPrefix}`);
  const [polling, setPolling] = React.useState(false);
  const [wordsHidden, setWordsHidden] = React.useState(true);

  //options
  const [preventSameUser, setPreventSameUser] = React.useState(true);
  const [preventSameUserGuess, setPreventSameUserGuess] = React.useState(true);

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (wordList.length >= settings.maxWords) {
      setPolling(false);
    }
  }, [wordList]);

  React.useEffect(() => {
    if (polling && prefixReg.test(msgState.msg)) {
      addWord(msgState);
    }
  }, [msgState]);

  const addWord = (msgObj) => {
    const msg = msgObj.msg.substring(settings.commandPrefix.length).trim();
    if (msg.length > settings.maxChars) {
      return;
    }

    if (msg.length < 1) {
      return;
    }

    const newWordList = wordList;

    //prevent dups
    const regTest = WordRegEx(msg, true);
    const wordExist = newWordList.find(
      ({ word, byUser }) =>
        regTest.test(word) ||
        (preventSameUser && byUser === msgObj.display_name)
    );

    if (!wordExist) {
      newWordList.unshift({
        word: msg,
        byUser: msgObj.display_name,
        userColour: msgObj.colour,
      });

      setWordList([...newWordList]);
    }
  };

  const clearAll = () => {
    setWordList([]);
  };

  const load = () => {
    //load options
    let options = {
      preventSameUser: preventSameUser,
      preventSameUserGuess: preventSameUserGuess,
    };
    const d = localStorage.getItem("Chat_Words_Save");
    if (d) {
      let saveObj = JSON.parse(d);
      options = {
        ...options,
        ...saveObj,
      };
      setPreventSameUser(options.preventSameUser);
      setPreventSameUserGuess(options.preventSameUserGuess);
    } else {
      //allow these options to be defualt true if viewer count is over 100
      setPreventSameUser(user.viewer_count && user.viewer_count > 100);
      // setPreventSameUserGuess(user.viewer_count && user.viewer_count > 500);
    }

    //load existing list
    if (payload.words && payload.words.length > 0) {
      setWordList(payload.words);
    }
  };

  const onExitSave = () => {
    //save options
    localStorage.setItem(
      "Chat_Words_Save",
      JSON.stringify({
        preventSameUser: preventSameUser,
        preventSameUserGuess: preventSameUserGuess,
      })
    );
    //reslove panel
    if (wordList.length > 0) {
      payload.reslovePanel(
        wordList.map((wordObj) => {
          return { ...wordObj, preventSameUserGuess: preventSameUserGuess };
        })
      );
    } else {
      payload.rejectPanel();
    }

    setPanel("MainMenu");
  };

  const fakeChat = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-y-6 text-white">
        <div className="bg-gray-900 p-3 w-2/3 text-lg">
          <span className="block">
            <span className="text-Twitch_Red font-bold">BotStop</span>
            <span>: !add Kappa</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Green font-bold">TheFinalSoul</span>
            <span>: !add Toy</span>
          </span>

          <span className="block">
            <span className="text-green-400 font-bold">Rulba</span>
            <span>: !add PogChamp</span>
          </span>

          <span className="block">
            <span className="text-yellow-300 font-bold">Pokelawls</span>
            <span>: !add FeelsGoodMan</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Aqua font-bold">excellentgeek</span>
            <span>: !add Boxing</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Red font-bold">karl-police</span>
            <span>: !add Dog</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Purple font-bold">Sotif</span>
            <span>: !add Cup</span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="p-3">
        <button
          className="border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black border-2 px-4 py-2 text-xl"
          onClick={onExitSave}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="ml-2">Save</span>
        </button>
      </div>

      <h3 className="font-bold text-4xl text-center self-center">
        {"Chat's Words"}
      </h3>
      <span className="block text-center mb-2">
        Have chat add words of their own
      </span>

      <div className="flex flex-col items-center justify-center mt-5 gap-y-5  pt-5">
        <span className="opacity-70 text-sm">
          Max Character Limit is {settings.maxChars}
        </span>

        <button
          className={`${
            polling
              ? "hover:bg-red-900 bg-red-800 btn-panel--enable"
              : `" ${
                  wordList.length >= settings.maxWords
                    ? "opacity-30 btn-panel--disabled cursor-default"
                    : "btn-panel--enable hover:bg-DTC_LighterGreen"
                } bg-DTC_Green  "`
          } 
           py-3 px-4 text-lg font-bold border-2 border-gray-200`}
          onClick={() => {
            if (wordList.length < settings.maxWords) {
              setPolling(!polling);
            }
          }}
        >
          {polling ? "Stop Polling" : "Start Polling"}
        </button>

        <CheckBox
          key={`preventSame-${preventSameUser}`}
          defualtValue={
            typeof preventSameUser !== "undefined" ? preventSameUser : false
          }
          label="Prevent Multiple Words From Chatter"
          tooltip="Don't allow a chatter to submit multiple words"
          onChange={(arg) => {
            setPreventSameUser(arg);
          }}
        />
        <CheckBox
          key={`preventSameGuess-${preventSameUserGuess}`}
          defualtValue={
            typeof preventSameUserGuess !== "undefined"
              ? preventSameUserGuess
              : false
          }
          label="Prevent Guessing Of Own Word"
          tooltip="Don't allow a chatter to guess their own word"
          onChange={(value) => {
            setPreventSameUserGuess(value);
          }}
        />

        {wordList.length > 0 && (
          <div className="flex flex-col items-center justify-between w-full px-2 gap-y-2">
            <button
              className="border-1 p-2 text-sm hover:bg-DTC_LightBlue hover:text-black"
              onClick={() => {
                setWordsHidden(!wordsHidden);
              }}
            >
              {wordsHidden ? "Show Words" : "Hide Words"}
            </button>

            <button
              className="p-2 border-red-800 border-1 hover:bg-red-900 mt-2"
              onClick={clearAll}
            >
              Clear All
            </button>
          </div>
        )}
      </div>

      <div className="my-5 flex flex-col items-center justify-center border-t border-opacity-50 pt-5">
        <span className="text-xl font-bold mb-2 block">
          Add a word by typing:
        </span>
        <span className="font-bold bg-gray-800 p-2 text-xl">
          {settings.commandPrefix}
        </span>
      </div>

      {wordList.length === 0 && fakeChat()}

      {wordList.length > 0 && (
        <div className="p-2 overflow-y-scroll flex flex-col justify-center w-full gap-y-4 mt-2">
          <div className="flex gap-x-4 font-bold justify-evenly text-lg border-b-2 border-opacity-30 pt-2">
            <span className="w-2/5">Username</span>
            <span className="w-2/5">Word</span>
            <span className="w-1/5">
              <span className="text-center block font-bold">
                <span
                  className={`text-center self-center  ${
                    wordList.length >= settings.maxWords && "text-red-600"
                  }`}
                >{`${wordList.length}/${settings.maxWords}`}</span>
              </span>
            </span>
          </div>
          {wordList.map((wordObj, index) => (
            <div
              key={`chat-word-${index}`}
              className="flex gap-x-4 justify-evenly items-start border-b-2 border-opacity-10"
            >
              <span
                className="w-2/5 overflow-x-scroll font-bold"
                style={{ color: wordObj.userColour }}
              >
                {wordObj.byUser}
              </span>
              <span className="w-2/5 overflow-x-scroll">
                {wordsHidden ? (
                  <span className="opacity-40 text-sm">{"(Hidden)"}</span>
                ) : (
                  wordObj.word
                )}
              </span>
              <button
                className="w-1/5"
                onClick={() => {
                  setWordList([
                    ...wordList.filter((w, wordIndex) => wordIndex !== index),
                  ]);
                }}
              >
                <FontAwesomeIcon
                  className="text-red-600 hover:text-red-700"
                  icon={faTrash}
                />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ChatWords.propTypes = {
  setPanel: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
};

export default ChatWords;

import * as React from "react";
import PropTypes from "prop-types";
import { Switch as HeadlessSwitch } from "@headlessui/react";

const Switch = ({ label, isChecked, onChange }) => {
  return (
    <div className="flex items-center">
      <HeadlessSwitch
        aria-label={label}
        checked={isChecked}
        onChange={onChange}
        className={`${
          isChecked ? "bg-DTC_Green" : "bg-DTC_Gray"
        } relative inline-flex shadow-inner rounded-sm items-center h-6 w-10 transition ease-in-out duration-200 `}
      >
        <span
          className={`${
            isChecked ? "translate-x-8" : "translate-x-0"
          } inline-block w-2 h-6 bg-white rounded-sm transform transition ease-in-out duration-200`}
        />
      </HeadlessSwitch>
    </div>
  );
};

Switch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default Switch;

import * as React from "react";

import SignIn from "./SignIn";
import LandingAnimation from "./LandingAnimation";
import Logo from "../../images/DTC2_Logo.inline.svg";
import packageInfo from "../../../package.json";

import BOTC_Icon from "../../images/BOTC_icon.png";

const Landing = () => {
  return (
    <div style={{ minHeight: "65rem" }} className=" relative h-screen">
      <LandingAnimation />
      <div className="relative z-20 spawn">
        <div className="text-center pt-20 mb-32 max-w-max mx-auto">
          <h1
            translate="no"
            className="text-5xl md:text-6xl lg:text-7xl font-bold"
          >
            Da Stream{" "}
            <span className="whitespace-nowrap">
              Charades
              <Logo className="inline w-12 h-12 lg:w-20 lg:h-20 ml-2" />
            </span>
          </h1>
          <div className="flex-none sm:flex justify-between">
            <div>
              <h2 className=" text-2xl lg:text-3xl text-DTC_Yellow font-bold">
                Play Charades On Stream!
              </h2>
            </div>

            <div translate="no" className="font-bold sm:mr-5">
              v{packageInfo.version}
            </div>
          </div>
        </div>

        <div className="text-center mx-auto h-52">
          <SignIn />
        </div>

        <div className="mt-24 text-center text-lg">
          <h2 className="text-xl font-bold text-yellow-200 mb-2">
            Other Games
          </h2>
          <a
            className="hover:underline font-bold"
            href="https://battleofthechat.net/"
          >
            <span translate="no">Battle Of The Chat</span>
            <img
              className="inline ml-1"
              src={BOTC_Icon}
              height={28}
              width={28}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Landing;

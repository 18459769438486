import * as React from "react";
import { GameContext } from "../../game/store";
import { GameStateEnum } from "../../game/gameEnums";
import Chat from "./Chat";
import NextBtn from "./NextBtn";
import PauseBtn from "./PauseBtn";
import RevealBtn from "./RevealBtn";
import ImageView from "./../utils/ImageView";

const ChatPanel = () => {
  const gameContext = React.useContext(GameContext);
  const [showChatPanel, setShowChatPanel] = React.useState(false);
  const [showWord, setShowWord] = React.useState(false);

  React.useEffect(() => {
    if (
      gameContext.state.gameState == GameStateEnum.Lost ||
      gameContext.state.gameState == GameStateEnum.Won
    ) {
      setShowWord(true);
    } else {
      setShowWord(false);
    }
    if (
      gameContext.state.gameState >= GameStateEnum.RoundStart &&
      !showChatPanel
    ) {
      setTimeout(() => {
        setShowChatPanel(true);
      }, 50);
    }
  }, [gameContext.state.gameState]);

  return React.useMemo(() => {
    return (
      <div
        style={{ minHeight: "895px" }}
        className={`mx-auto py-10 px-5 text-center  relative ${
          showChatPanel ? "opacity-100" : "opacity-0"
        } transition-opacity duration-500`}
      >
        <div>
          <ImageView showWord={showWord} height={150} />
          <div className="font-bold text-5xl mt-2 mb-4 mx-auto">
            {showWord ? (
              <span translate="no">{gameContext.state.wordInPlay.word}</span>
            ) : (
              <span translate="no">{"???"}</span>
            )}
          </div>
          <Chat />
          <div className="relative w-full mt-10">
            <div className="absolute z-10">
              <PauseBtn />
            </div>
            <div className="self-center relative mx-auto">
              <NextBtn />
              <div className="mt-3">
                <RevealBtn />
              </div>
            </div>
            <div />
          </div>
        </div>
      </div>
    );
  }, [showChatPanel, gameContext.state.wordInPlay.word, showWord]);
};

export default ChatPanel;

let client_id = "zreyqa3hucl5rsnk22viomkd9sapqb";
let redirect_uri =
  "http://localhost:8000/auth/?t=RG9yaW1lSW50ZXJpbW9hZGFwYXJlRG9yaW1lQW1lbm9BbWVub0xhdGlyZUxhdGlyZW1vRG9yaW1lQW1lbm9PbWVuYXJlaW1wZXJhdmlhbWVub0RpbWVyZWRpbWVyZW1hdGlyb01hdGlyZW1vQW1lbm9PbWVuYXJlaW1wZXJhdmllbXVsYXJpYW1lbm9PbWVuYXJlaW1wZXJhdmllbXVsYXJpYW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lYW1BbWVub09tZW5hcmVpbXBlcmF2aWFtZW5vRGltZXJlZGltZXJlbWF0aXJvTWF0aXJlbW9BbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVBbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lZG9tQW1lbm9kb3JlQW1lbm9kb3JpbWVBbWVub2RvcmltZUFtZW5vZG9tRG9yaW1lcmVvQW1lbm9kb3JpbWVEb3JpbWVJbnRlcmltb2FkYXBhcmVEb3JpbWVBbWVub0FtZW5vTGF0aXJlTGF0aXJlbW9Eb3JpbWVBbWVub09tZW5hcmVpbXBlcmF2aWFtZW5vRGltZXJlZGltZXJlbWF0aXJvTWF0aXJlbW9BbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVhbUFtZW5vQW1lbm9kb3JlQW1lbm9kb3JpbWVBbWVub2RvcmltZUFtZW5vZG9tRG9yaW1lcmVvQW1lbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lYW1BbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVhbUFtZW5vRG9yaW1lcmVvQW1lbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21BbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZQ";

let debugContextMenu = false;
/**
 * Sigin as a different channel then what is signed in on twitch. Note: emote Fetching will not work
 */
let debugChannel = "";
/**
 * Mounts ListImageView Component and WordList Switch in WordBank.js
 */
let debugWordBank = false;
/**
 * Skips word shuffling
 */
let skipShuffle = false;
/**
 * Use debug settings in a production build
 */
let useDebugInProduction = false;
/**
 * Bypass the need to sign in. (Chat and dynamic categories will not work)
 */
let bypassChatRequirement = false;
/**
 * Category editor ui (Note will use the json file not the categories class array)
 */
let staticCategoryEditor = false;
/**
 * Do Not Edit
 */
if (process.env.NODE_ENV === "production" && !useDebugInProduction) {
  client_id = "gcxxg3g0lghqxl0d4zhttv11sobtf8";
  redirect_uri =
    "https://dastreamcharades.net/auth/?t=RG9yaW1lSW50ZXJpbW9hZGFwYXJlRG9yaW1lQW1lbm9BbWVub0xhdGlyZUxhdGlyZW1vRG9yaW1lQW1lbm9PbWVuYXJlaW1wZXJhdmlhbWVub0RpbWVyZWRpbWVyZW1hdGlyb01hdGlyZW1vQW1lbm9PbWVuYXJlaW1wZXJhdmllbXVsYXJpYW1lbm9PbWVuYXJlaW1wZXJhdmllbXVsYXJpYW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lYW1BbWVub09tZW5hcmVpbXBlcmF2aWFtZW5vRGltZXJlZGltZXJlbWF0aXJvTWF0aXJlbW9BbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVBbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lZG9tQW1lbm9kb3JlQW1lbm9kb3JpbWVBbWVub2RvcmltZUFtZW5vZG9tRG9yaW1lcmVvQW1lbm9kb3JpbWVEb3JpbWVJbnRlcmltb2FkYXBhcmVEb3JpbWVBbWVub0FtZW5vTGF0aXJlTGF0aXJlbW9Eb3JpbWVBbWVub09tZW5hcmVpbXBlcmF2aWFtZW5vRGltZXJlZGltZXJlbWF0aXJvTWF0aXJlbW9BbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVhbUFtZW5vQW1lbm9kb3JlQW1lbm9kb3JpbWVBbWVub2RvcmltZUFtZW5vZG9tRG9yaW1lcmVvQW1lbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lYW1BbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVhbUFtZW5vRG9yaW1lcmVvQW1lbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21BbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZQ";
  debugChannel = "";
  debugWordBank = false;
  skipShuffle = false;
  debugContextMenu = false;
  bypassChatRequirement = false;
  staticCategoryEditor = false;
}

export {
  client_id,
  redirect_uri,
  debugChannel,
  debugWordBank,
  skipShuffle,
  debugContextMenu,
  bypassChatRequirement,
  staticCategoryEditor,
};

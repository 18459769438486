import { AuthTypeEnum } from "../game/gameEnums.mjs";
import axios from "axios";
import TwitchJs from "twitch-js";

const RegExpEscape = (s) => {
  //Removed "-". Fine since we are not using []
  // eslint-disable-next-line no-useless-escape
  return s.replace(/[\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

const WordRegEx = (word, nonStrict) => {
  let prepWord = RegExpEscape(word).toLowerCase();

  if (nonStrict) {
    let optionalChars = [
      "-", //dont' escape cuz RegExpEscape does not escape it
      "ß",
      "¿",
      "¡",
      "\\?", //escape regex key letters
      "!",
      "€",
      "\\.", //escape regex key letters
      ",",
      "œ",
      ";",
      ":",
      "’",
      // prettier-ignore
      "\"",
      "`",
      ">",
      "<",
      "_",
    ];
    const optionalCharsException = [{ char: "'", except: "'s" }];
    const replaceChars = [
      { chars: ["é", "è", "ë", "ê", "ę"], replace: "e" },
      { chars: ["ä", "à", "á", "â", "ą"], replace: "a" },
      { chars: ["î", "ï", "í", "ì"], replace: "i" },
      { chars: ["ö", "ó", "ô", "ò"], replace: "o" },
      { chars: ["ü", "ù", "ú", "ü"], replace: "u" },
      { chars: ["ç", "ć"], replace: "c" },
      { chars: ["ñ", "ń"], replace: "n" },
      { chars: ["ß"], replace: "ss" },
      { chars: ["ł"], replace: "l" },
      { chars: ["ś"], replace: "s" },
      { chars: ["ź", "ż"], replace: "z" },
    ];

    //optional chars
    optionalChars.forEach((char) => {
      prepWord = prepWord.replace(
        new RegExp(RegExpEscape(char), "g"),
        `(${char}| |)`
      );
    });

    //optional chars with exception
    optionalCharsException.forEach((charObj) => {
      if (!prepWord.includes(charObj.except)) {
        prepWord = prepWord.replace(
          new RegExp(RegExpEscape(charObj.char), "g"),
          `(${charObj.char}| |)`
        );
      }
    });

    //replace chars
    replaceChars.forEach((charObj) => {
      charObj.chars.forEach((char) => {
        prepWord = prepWord.replace(
          new RegExp(`${char}`, "g"),
          `(${charObj.replace}|${char})`
        );
      });
    });

    //single char edge cases
    if (prepWord.includes(" & ")) {
      prepWord = prepWord.replace(/ & /g, "( & | and | )");
    } else {
      prepWord = prepWord.replace(/&/g, "(&|and| | & | and |)");
    }

    if (prepWord.includes("'s") || prepWord.includes("’s")) {
      prepWord = prepWord.replace(/'s/g, "(’s|'s|s|)");
      prepWord = prepWord.replace(/’s/g, "(’s|'s|s|)");
    } else {
      prepWord = prepWord.replace(/'/g, "('|)");
      prepWord = prepWord.replace(/’/g, "(’|)");
    }
    //pharses
    if (!(prepWord.includes(" of the ") || prepWord.includes(" and the "))) {
      prepWord = prepWord.replace(/\bthe /g, "(the |)");
    } else {
      prepWord = prepWord.replace(/^the /, "(the |)");
    }

    prepWord = prepWord.replace(/\bel /g, "(el |)");

    prepWord = prepWord.replace(/\bla /g, "(la |)");
  }

  return new RegExp(`^${prepWord}$`, "u");
};

const ShuffleArray = (a) => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

const SetInputFilter = (textbox, inputFilter, defualtValue) => {
  textbox.value = defualtValue;
  [
    "input",
    "keydown",
    "keyup",
    "mousedown",
    "mouseup",
    "select",
    "contextmenu",
    "drop",
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (Object.prototype.hasOwnProperty.call(this, "oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
};

const CalcVolume = (masterVolume, sub = 0) => {
  let volume = masterVolume - sub;
  return volume < 0 ? 0 : volume;
};

const DataLayerPush = (eventName, data = {}) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({ event: eventName, ...data });
  } else if (process.env.NODE_ENV === "production") {
    console.error("dataLayer undefined");
  }
};

const GetAuthTypeString = (authType) => {
  switch (authType) {
    case AuthTypeEnum.Twitch:
      return "Twitch";
    case AuthTypeEnum.Youtube:
      return "Youtube";
    case AuthTypeEnum.TwitchChannelName:
      return "Twitch Channel Name";
    default:
      console.warn("Failed to get auth type string");
      return "Unknown";
  }
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getTwitchChannelIDByDisplayName = (token, client_id, channelName) => {
  const { chat } = new TwitchJs({
    token: token,
    username: channelName,
    clientId: client_id,
    log: { level: "silent" },
  });

  return new Promise((reslove, reject) => {
    chat
      .connect()
      .then(() => {
        //timeout if joining takes too long
        //God Code: only god knows why i placed this timeeout
        setTimeout(() => {
          if (chat) {
            chat.disconnect();
            reject();
          }
        }, 30000);

        chat
          .join(channelName)
          .then(({ roomState }) => {
            const cloneState = structuredClone(roomState);
            chat.disconnect();
            reslove({ display_name: channelName, id: cloneState.roomId });
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

const getTwitchChannelData = (token, client_id, channelName) => {
  return new Promise((reslove, reject) => {
    let url = "";
    if (typeof channelName === "string") {
      if (channelName.length > 0) {
        url = `https://api.twitch.tv/helix/users?login=${channelName}`;
      } else {
        return reject("channel name empty");
      }
    } else {
      url = "https://api.twitch.tv/helix/users";
    }

    axios({
      method: "get",
      url: url,
      headers: {
        "Client-Id": client_id,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let userData = res.data.data[0];
        //Get Stream data
        axios({
          method: "get",
          url: `https://api.twitch.tv/helix/streams?user_id=${userData.id}`,
          headers: {
            "Client-Id": client_id,
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res2) => {
            let streamData = res2.data.data[0];
            reslove({ ...userData, ...streamData });
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export {
  WordRegEx,
  RegExpEscape,
  ShuffleArray,
  SetInputFilter,
  CalcVolume,
  DataLayerPush,
  GetAuthTypeString,
  getRandomInt,
  getTwitchChannelData,
  getTwitchChannelIDByDisplayName,
};

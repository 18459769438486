import * as React from "react";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
//https://www.npmjs.com/package/react-tooltip

const CheckBox = ({ onChange, defualtValue = false, label, tooltip }) => {
  const [val, setVal] = React.useState(defualtValue);

  const change = () => {
    let newValue = !val;
    onChange(newValue);
    setVal(newValue);
  };

  const content = () => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={(e) => {
            change();
            e.stopPropagation();
          }}
          role="button"
        >
          <span className="mr-5 select-none cursor-pointer" htmlFor={label}>
            {label}
            {tooltip && (
              <span>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  size="sm"
                  className="ml-1 mb-1"
                />
              </span>
            )}
          </span>
          <input
            className="cursor-pointer accent-black w-5 h-5"
            id={label}
            type="checkbox"
            checked={val}
            aria-checked={val}
            readOnly={true}
          ></input>
        </span>
      </>
    );
  };

  return (
    <>
      {tooltip ? (
        <Tooltip tip={tooltip}>{content()}</Tooltip>
      ) : (
        <>{content()}</>
      )}
    </>
  );
};

CheckBox.propTypes = {
  defualtValue: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default CheckBox;

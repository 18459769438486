import React from "react";
import PropTypes from "prop-types";
import { GameContext } from "../game/store";
import { AuthTypeEnum } from "../game/gameEnums.mjs";

import TwitchSVG from "../images/twitch.svg";
const ChannelName = ({ mininal = false }) => {
  const gameContext = React.useContext(GameContext);
  return (
    <>
      <span className="mx-2">
        {(gameContext.state.user.authType === AuthTypeEnum.Twitch ||
          gameContext.state.user.authType ===
            AuthTypeEnum.TwitchChannelName) && (
          <img
            className="mx-auto select-none inline"
            src={TwitchSVG}
            width={30}
          />
        )}
      </span>
      <span translate="no" className="font-bold">
        {gameContext.state.user.display_name}
      </span>
      {gameContext.state.user.authType === AuthTypeEnum.TwitchChannelName &&
        !mininal && (
          <span className="ml-1 text-sm opacity-60">{"(Channel Name)"}</span>
        )}
    </>
  );
};

ChannelName.propTypes = {
  mininal: PropTypes.bool,
};

export default ChannelName;

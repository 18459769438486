import * as React from "react";
import PropTypes from "prop-types";

const TimerHeadless = ({
  timeInSecs,
  onStart,
  onEnd = () => null,
  onTick,
  spanClass,
  startVar = null,
  hideContent = false,
}) => {
  const [seconds, setSeconds] = React.useState(timeInSecs);
  const [displaySecs, setDisplaySecs] = React.useState("0");
  const [displayMin, setDisplayMin] = React.useState("");
  const secondsRef = React.useRef();
  const tickerRef = React.useRef();

  secondsRef.current = seconds;

  React.useEffect(() => {
    if (startVar === null) {
      Start();
    }
    return () => Stop(false);
  }, []);

  React.useEffect(() => {
    if (startVar) {
      Start();
    }
  }, [startVar]);

  const Start = () => {
    if (onStart) {
      onStart();
    }

    tickerRef.current = setInterval(Tick, 1000);
  };

  React.useEffect(() => {
    let newDisplaySecs = parseInt(seconds % 60, 10);
    let newDisplayMin = "";
    if (seconds >= 60) {
      newDisplayMin = parseInt(seconds / 60, 10).toString() + ":";
      newDisplaySecs =
        newDisplaySecs < 10 ? "0" + newDisplaySecs : newDisplaySecs;
    }

    if (seconds === 0) {
      Stop(true);
    } else if (onTick) {
      onTick(seconds);
    }

    setDisplaySecs(newDisplaySecs);
    setDisplayMin(newDisplayMin);
  }, [seconds]);

  const Tick = () => {
    setSeconds(secondsRef.current - 1);
  };

  const Stop = (doEndCall = true) => {
    if (doEndCall) {
      onEnd();
    }
    clearInterval(tickerRef.current);
  };

  return (
    !hideContent && (
      <span className={spanClass}>
        <span translate="no">{displayMin}</span>
        <span translate="no">{displaySecs}</span>
      </span>
    )
  );
};

TimerHeadless.propTypes = {
  timeInSecs: PropTypes.number.isRequired,
  onStart: PropTypes.func,
  onEnd: PropTypes.func,
  onTick: PropTypes.func,
  spanClass: PropTypes.string,
  startVar: PropTypes.bool,
  hideContent: PropTypes.bool,
};

export default TimerHeadless;

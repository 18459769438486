import * as React from "react";

import { GameContext } from "../game/store.js";
import { GameStateEnum } from "../game/gameEnums.mjs";
import { Transition } from "@headlessui/react";

import Landing from "./signIn/Landing";
import SidePanel from "./SidePanel/SidePanel";
import ErrorDialog from "./ErrorDialog";
import WinAnimation from "./chatpanel/WinAnimation";
import StartBtn from "./StartBtn";
import HowToPlay from "./HowToPlay";
import useShowPanel from "./utils/useShowPanel";
import PlayPanel from "./PlayPanel.js";

const MainContent = () => {
  const gameContext = React.useContext(GameContext);
  const showPanel = useShowPanel();

  return React.useMemo(() => {
    return (
      <main className="relative ">
        <ErrorDialog />
        {/* Landing Page */}

        <Transition
          show={gameContext.state.gameState === GameStateEnum.SignIn}
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Landing />
        </Transition>

        {/* Main Layout */}
        {showPanel && gameContext.state.gameState >= GameStateEnum.RoundStart && (
          <div
            onClick={() => {
              gameContext.dispatch({
                type: "UpdateShowSidePanel",
                payload: { showSidePanel: false },
              });
            }}
            className="h-full w-full absolute z-20 cursor-pointer"
          ></div>
        )}
        {gameContext.state.gameState >= GameStateEnum.Authed && (
          <WinAnimation />
        )}

        <div
          style={{ minHeight: "65rem" }}
          className={`w-full ${
            gameContext.state.gameState >= GameStateEnum.Authed
              ? gameContext.state.gameState < GameStateEnum.RoundStart
                ? ""
                : "block"
              : "hidden"
          } `}
        >
          <div className="md:flex justify-between">
            <div className="flex-none">
              <SidePanel />
            </div>

            {gameContext.state.gameState < GameStateEnum.RoundStart && (
              <div className="self-center my-24 md:my-0">
                <StartBtn />
                <HowToPlay />
              </div>
            )}
            <div />
          </div>

          <div
            className={`mx-2 md:ml-10 ${
              gameContext.state.gameState >= GameStateEnum.RoundStart
                ? "block"
                : "hidden"
            }`}
          >
            <PlayPanel />
          </div>
        </div>
      </main>
    );
  }, [
    showPanel,
    gameContext.state.gameState,
    gameContext.state.wordListPrimed,
    gameContext.state.chatConnected,
  ]);
};

export default MainContent;

// import fs from "fs";
// let file = fs.readFileSync(
//   `src/game/categories/${"2_Pokemon"}.category`,
//   "utf-8"
// );
// let obj = parseCategoryFile(file);
// let markdown = objToCategoryMarkdown(obj);
// console.log(JSON.stringify(obj));
// console.log(markdown);

import { FiltersEnum } from "../gameEnums.mjs";

function parseCategoryFile(data) {
  let categoryObj = {};

  let splitTargetBlock = data.indexOf("---");
  let doubleBlock = [
    data.slice(0, splitTargetBlock),
    data.slice(splitTargetBlock + 3),
  ];

  let catOptionsSegments = doubleBlock[0].trim().split("??");
  let wordSegments = doubleBlock[1].trim().split("::");

  //parse category options
  catOptionsSegments.forEach((segment) => {
    let splitTarget = segment.trim().indexOf("=");
    let keyPair = [
      segment.trim().slice(0, splitTarget),
      segment.trim().slice(splitTarget + 1),
    ];
    switch (keyPair[0].trim()) {
      case "name":
        categoryObj.name = keyPair[1].trim();
        break;
      case "isOn":
        categoryObj.isOn = keyPair[1].trim() === "true" ? true : false;
        break;
      case "filterEnum": //TODO: parse to FilterEnums
        categoryObj.filterEnum =
          FiltersEnum[
            Object.keys(FiltersEnum).find(
              (filter) => filter === keyPair[1].trim()
            )
          ];
        break;
      case "translateTitle":
        categoryObj.translateTitle = keyPair[1].trim();
        break;
      case "defualtTranslate":
        categoryObj.defualtTranslate = keyPair[1].trim();
        break;
      default:
        if (keyPair[0].trim().length > 0) {
          console.log(
            `WARN: Category option was not found: "${keyPair[0].trim()}"`
          );
        }
    }
  });

  //parse words
  categoryObj.words = [];
  wordSegments.forEach((segment) => {
    if (segment.trim().length > 0) {
      let wordObj = {};

      //setup word meta data
      let metaSegments = segment.split(">>");
      let wordMeta = metaSegments[0].trim().split("&&");

      wordObj.word = wordMeta[0].trim();

      //parse word alt words
      wordMeta.shift(); // remove main word
      if (wordMeta.length > 0) {
        wordObj.altWords = [];
        wordMeta.forEach((altWordSegment) => {
          const segments = altWordSegment.trim().split("??");
          const altWord = segments[0];
          const altWordObj = {};
          segments.shift();
          //check if alt word is a different lang
          if (altWord.includes("=")) {
            let targetIndex = altWord.indexOf("=");
            let splitSegments = [
              altWord.slice(0, targetIndex).trim(),
              altWord.slice(targetIndex + 1).trim(),
            ];
            altWordObj["word"] = splitSegments[1];
            altWordObj["lang"] = splitSegments[0];
          } else {
            altWordObj["word"] = altWord;
          }
          //parse alt word options
          segments.forEach((option) => {
            switch (option.trim()) {
              case "translated":
                altWordObj["translated"] = true;
                break;
            }
          });

          wordObj.altWords.push(altWordObj);
        });
      }

      //parse image url
      if (metaSegments[1]) {
        let imageURL = metaSegments[1].trim();
        wordObj.imageURL = imageURL;
      }

      categoryObj.words.push(wordObj);
    }
  });

  // console.log(JSON.stringify(categoryObj));
  // console.log(categoryObj);
  return categoryObj;
}

function objToCategoryMarkdown(obj) {
  let fileData = "";

  //category options
  fileData += Object.keys(obj)
    .filter((key) => key !== "words" && key !== "posiiton")
    .map((key) => {
      switch (key) {
        case "filterEnum":
          return `filterEnum=${Object.keys(FiltersEnum).find((filterKey) => {
            return FiltersEnum[filterKey] === obj[key];
          })} ??\n`;
        default:
          return `${key}=${obj[key]} ??\n`;
      }
    })
    .join("");

  fileData += "---\n";

  //markdown words
  fileData += obj.words
    .map((word) => {
      let markdownString = "";
      //main word
      markdownString += `${word.word.trim()}`;

      //markdown alt words
      if (word.altWords) {
        markdownString += word.altWords
          .map((altWord) => {
            if (altWord.lang) {
              return ` && ${altWord.lang.trim()}=${altWord.word.trim()}${
                altWord.translated ? " ?? translated" : ""
              }`;
            }
            return ` && ${altWord.word.trim()}`;
          })
          .join("");
      }

      //markdown picture assets
      if (word.imageURL) {
        markdownString += " >> ";
        markdownString += word.imageURL;
      }

      markdownString += " ::\n";
      return markdownString;
    })
    .join("");
  return fileData;
}

export { parseCategoryFile, objToCategoryMarkdown };

import * as React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faStar } from "@fortawesome/free-solid-svg-icons";
import { GameContext } from "../../../game/store";
import Button from "../../utils/Button";
import WordBank from "./WordBank";
import WordWindow from "./WordWindow";
import ChannelName from "../../ChannelName";
import { DataLayerPush } from "../../../utils/browser_utils";

const MainMenu = ({ setPanel }) => {
  const gameContext = React.useContext(GameContext);
  return React.useMemo(() => {
    return (
      <div className="relative h-full">
        <WordBank setPanel={setPanel} />

        <div className="mt-6">
          <div className="flex justify-center">
            <Button
              OnClick={() => {
                setPanel("ScoreBoard");
                DataLayerPush("click_scoreboard");
              }}
            >
              <div className="border-2 border-DTC_Yellow p-3 font-semibold hover:bg-DTC_Yellow hover:text-black gap-x-4">
                Scoreboard
                <FontAwesomeIcon icon={faStar} size="lg" className="ml-2" />
              </div>
            </Button>
          </div>
          <div className="flex justify-center mt-2 gap-x-4">
            <Button OnClick={() => setPanel("Settings")}>
              <div className="border-2 border-DTC_Yellow p-3 font-semibold hover:bg-DTC_Yellow hover:text-black">
                Settings
                <FontAwesomeIcon icon={faCog} size="lg" className="ml-2" />
              </div>
            </Button>
            <WordWindow />
          </div>

          <div className="absolute bottom-0 my-1">
            <ChannelName />
          </div>
        </div>
      </div>
    );
  }, [gameContext.state.user, setPanel]);
};

MainMenu.propTypes = {
  setPanel: PropTypes.func.isRequired,
};

export default MainMenu;

import React from "react";
import PropTypes from "prop-types";
import Button from "../utils/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { GameContext } from "../../game/store";
import ChannelName from "../ChannelName";
import {
  calcStreamerScore,
  numberWithCommas,
} from "../chatpanel/ScoreBoardUtils";

const ScoreBoard = ({ setPanel }) => {
  const gameContext = React.useContext(GameContext);

  React.useEffect(() => {
    load();
  }, [gameContext.state.user.login]);

  React.useEffect(() => {
    save();
  }, [
    gameContext.state.scoreList.length,
    gameContext.state.rounds,
    gameContext.state.roundsWon,
  ]);

  const save = () => {
    const saveData = {
      date: new Date(),
      scoreList: gameContext.state.scoreList,
      rounds: gameContext.state.rounds,
      roundsWon: gameContext.state.roundsWon,
    };

    localStorage.setItem(
      `scoreboard_${gameContext.state.user.login}`,
      JSON.stringify(saveData)
    );
  };

  const load = () => {
    const saveString = localStorage.getItem(
      `scoreboard_${gameContext.state.user.login}`
    );

    if (!saveString) {
      return;
    }

    const saveObj = JSON.parse(saveString);

    const saveTimeLimit =
      new Date(saveObj.date).getTime() + 1 * 24 * 60 * 60 * 1000; // add one day
    const currentDate = new Date();

    //only load save that are less then a day old
    if (currentDate > saveTimeLimit) {
      return;
    }

    gameContext.dispatch({
      type: "UpdateScoreList",
      payload: { scoreList: saveObj.scoreList },
    });
    gameContext.dispatch({
      type: "UpdateRounds",
      payload: { rounds: saveObj.rounds },
    });
    gameContext.dispatch({
      type: "UpdateRoundsWon",
      payload: { roundsWon: saveObj.roundsWon },
    });
  };

  const clear = () => {
    gameContext.dispatch({
      type: "UpdateScoreList",
      payload: { scoreList: [] },
    });
    gameContext.dispatch({
      type: "UpdateRounds",
      payload: { rounds: 0 },
    });
    gameContext.dispatch({
      type: "UpdateRoundsWon",
      payload: { roundsWon: 0 },
    });

    //save is cleared automatically, cuz of useEffect
  };

  const OnBack = () => {
    setPanel("MainMenu");
  };
  //save rounds
  const listScores = () => {
    return gameContext.state.scoreList.map((scoreObj, index) => (
      <React.Fragment
        key={`top-${scoreObj.username}-${scoreObj.score}-${index}`}
      >
        <span
          className="break-all font-bold flex justify-between my-1 py-2"
          translate="no"
        >
          <span className="w-12 text-xl"> {displayNumber(index)}. </span>
          <span
            style={{ color: scoreObj.colour }}
            className="font-bold self-start w-60"
            translate="no"
          >
            {scoreObj.username}
          </span>{" "}
          <span className="text-yellow-600 w-32 text-right">
            {numberWithCommas(scoreObj.score)}
          </span>
        </span>
        <div className=" flex justify-center items-center ">
          <hr className="border w-full opacity-10" />
        </div>
      </React.Fragment>
    ));
  };

  const displayNumber = (number) => {
    switch (number) {
      default:
        return number + 1;
    }
  };

  return (
    <div className="p-3 h-full relative">
      <Button OnClick={OnBack}>
        <div className="text-xl px-4 py-2 border-2 border-DTC_Yellow p-3 hover:bg-DTC_Yellow hover:text-black">
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </div>
      </Button>
      <h3 className="font-bold mt-2 mb-5 text-4xl text-center self-center align-middle">
        Scoreboard
      </h3>
      <div className="mt-3 flex justify-between">
        <span className="font-bold text-lg">
          <ChannelName mininal={true} /> :{" "}
          <span className="text-yellow-600">
            {numberWithCommas(
              calcStreamerScore(
                gameContext.state.scoreList,
                gameContext.state.settings.maxTop,
                gameContext.state.roundsWon
              )
            )}
          </span>
        </span>
        <span>Rounds: {gameContext.state.rounds}</span>
      </div>
      <div className="w-full h-4/6 flex justify-center bg-gray-800 bg-opacity-30 overflow-y-scroll mt-5">
        {gameContext.state.scoreList.length != 0 ? (
          <div className="text-lg">{listScores()}</div>
        ) : (
          <span className="text-lg opacity-60 self-center place-self-center justify-self-center">
            {"Nothing Yet"}
          </span>
        )}
      </div>

      <div className=" flex justify-center w-full items-center mt-10 ">
        <button
          onClick={() => {
            clear();
          }}
          className=" block text-red-500 p-2 border-1 border-red-500 hover:bg-red-500 hover:text-white"
        >
          Erase
        </button>
      </div>
    </div>
  );
};

ScoreBoard.propTypes = {
  setPanel: PropTypes.func.isRequired,
};

export default ScoreBoard;

import * as React from "react";
import PropTypes from "prop-types";

const Button = ({
  OnClick,
  label,
  isLink = false,
  href,
  children,
  disabled = false,
}) => {
  const click = (e) => {
    if (!disabled) {
      OnClick(e);
    }
  };

  function RenderType() {
    if (isLink) {
      return (
        <a
          tabIndex={disabled ? "-1" : "0"}
          href={href}
          className="inline-block"
          aria-label={label}
        >
          {children}
        </a>
      );
    } else {
      return (
        <button
          tabIndex={disabled ? "-1" : "0"}
          type="button"
          aria-label={label}
          onClick={click}
          className={`${disabled && "cursor-default"}`}
        >
          {children}
        </button>
      );
    }
  }
  return <>{RenderType()}</>;
};

Button.propTypes = {
  isLink: PropTypes.bool,
  href: PropTypes.string,
  OnClick: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default Button;

import * as React from "react";
import { Disclosure } from "@headlessui/react";
import { DataLayerPush } from "../utils/browser_utils";
import { GameContext } from "../game/store";

const HowToPlay = () => {
  const gameContext = React.useContext(GameContext);
  return (
    <div className="mx-auto mt-4 max-w-2xl text-center">
      <Disclosure>
        <Disclosure.Button className="py-2">
          <h3
            onClick={() => {
              DataLayerPush("click_howToPlay");
            }}
            className="font-bold text-sm mb-4 text-gray-200  bg-gray-600 hover:bg-gray-500 p-2"
          >
            How To Play
          </h3>
        </Disclosure.Button>
        <Disclosure.Panel className="text-left text-gray-200 bg-DTC_DarkLightBlue border-1 border-white px-3 py-5 rounded-lg w-max">
          <>
            <p>
              1. At least have one category selected
              <br />
              2. Press Begin
              <br />
              3. Keep word window away from stream
              <br />
              4. Press Next for another word
            </p>

            <h3 className="font-bold mt-4 mb-4 text-center">Notes</h3>

            <p>- To make a guess, type a word in the chat</p>
            <p>
              - Viewer scores are calculated by how fast and frequent they win a
              round
            </p>
            <p>
              - Streamer score are calculated by the total scores of the top{" "}
              {gameContext.state.settings.maxTop} and the total rounds they have
              won
            </p>
          </>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

export default HowToPlay;

import * as React from "react";
import "../scss/globals.scss";

import { initialState, GameContext } from "../game/store";
import { debugContextMenu, staticCategoryEditor } from "../api";
import reducer from "../game/reducer";

import MainContent from "../components/MainContent";
import DebugMenu from "../components/utils/Debug/DebugMenu";
import StaticCategoryEditor from "../components/utils/Debug/StaticCategoryEditor";
import Layout from "../components/Layout";

const IndexPage = () => {
  const [gameState, stateDispatch] = React.useReducer(reducer, initialState);
  const store = React.useMemo(
    () => ({ state: gameState, dispatch: stateDispatch }),
    [gameState]
  );

  return (
    <>
      <div style={{ minWidth: "275px" }}>
        <Layout>
          <GameContext.Provider value={store}>
            {debugContextMenu && <DebugMenu />}
            {staticCategoryEditor && <StaticCategoryEditor />}
            <MainContent />
          </GameContext.Provider>
        </Layout>
      </div>
    </>
  );
};

export default IndexPage;

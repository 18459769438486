import * as React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import Button from "../../utils/Button";
import { GameContext } from "../../../game/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const defaultTextField = {
  index: null,
  word: "",
  imageURL: "",
};

const CustomWords = ({ setPanel, payload }) => {
  const gameContext = React.useContext(GameContext);
  const [textFields, setTextFields] = React.useState([
    { ...structuredClone(defaultTextField), index: 0 },
  ]);

  const importInputRef = React.useRef();

  React.useEffect(() => {
    populateFields();
  }, []);

  const populateFields = () => {
    //try to load from save
    if (gameContext.state.settings.canDoLocalStorage) {
      try {
        //edge case for versions before 2.2.0
        const oldSave = getOldSaveText();
        if (oldSave) {
          let newTextFields = parseOldMarkdown(oldSave);
          if (newTextFields.length < 1) {
            return;
          }
          newTextFields = newTextFields.map((wordObj, index) => {
            return { ...wordObj, index: index };
          });
          setTextFields(newTextFields);
        } else {
          //for version after 2.2.0
          let string = localStorage.getItem("custom_word");
          if (string != null && string.length > 0) {
            const newTextFields = parseJSON(string);
            if (newTextFields.length < 1) {
              return;
            }
            setTextFields(newTextFields);
          }
        }
      } catch (err) {
        console.warn(err);
        gameContext.dispatch({ type: "FailedLocalStorage" });
      }
    }
  };

  const saveFields = (textFieldProcessed) => {
    if (gameContext.state.settings.canDoLocalStorage) {
      try {
        localStorage.setItem("custom_word", JSON.stringify(textFieldProcessed));
      } catch (err) {
        console.warn(err);
        gameContext.dispatch({ type: "FailedLocalStorage" });
      }
    }
  };

  const parseJSON = (JSONString) => {
    let newTextFields = JSON.parse(JSONString);
    newTextFields = newTextFields.map((fieldObj, index) => {
      return {
        word: DOMPurify.sanitize(fieldObj.word),
        imageURL: DOMPurify.sanitize(fieldObj.imageURL),
        index: index,
      };
    });
    return processTextFields(newTextFields, true);
  };

  const exportJSON = () => {
    const textFieldProcessed = processTextFields(textFields);
    if (textFieldProcessed.length < 1) {
      return;
    }

    const dataUri =
      "data:application/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(textFieldProcessed));

    const exportFileDefaultName = `DSC_Custom_Words_${new Date().getTime()}.json`;
    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  };

  const importJSON = ({ target }) => {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      const newTextFields = parseJSON(event.target.result);
      importInputRef.current.value = null;
      if (newTextFields.length < 1) {
        return;
      }
      setTextFields(newTextFields);
    });
    reader.readAsText(target.files[0]);
  };

  const onExit = () => {
    //don't add words if empty and remove unless props
    const textFieldProcessed = processTextFields(textFields);

    saveFields(textFieldProcessed);

    if (textFieldProcessed.length != 0) {
      payload.reslovePanel(textFieldProcessed);
    } else {
      payload.rejectPanel();
    }
    setPanel("MainMenu");
  };

  const processTextFields = (textFields, addIndexs = false) => {
    return textFields
      .filter((fieldObj) => fieldObj.word.length > 0)
      .map((fieldObj, index) => {
        if (addIndexs) {
          return {
            word: fieldObj.word,
            imageURL: fieldObj.imageURL,
            index: index,
          };
        }
        return { word: fieldObj.word, imageURL: fieldObj.imageURL };
      });
  };

  const onChangeWord = (newWord, index) => {
    if (newWord.length > gameContext.state.settings.maxCharacterCount) {
      return;
    }
    const newTextFields = textFields;
    textFields[index].word = newWord;
    setTextFields([...newTextFields]);
  };

  const onChangeImageURL = (imageURL, index) => {
    const newTextFields = textFields;
    textFields[index].imageURL = imageURL;
    setTextFields([...newTextFields]);
  };

  const addField = () => {
    const newTextFields = textFields;
    textFields.push({
      ...structuredClone(defaultTextField),
      index: newTextFields.length,
    });
    setTextFields([...newTextFields]);
  };

  const deleteField = (index) => {
    let newTextFields = textFields;
    newTextFields.splice(index, 1);
    newTextFields = newTextFields.map((fieldObj, index) => {
      return { ...fieldObj, index: index };
    });
    setTextFields([...newTextFields]);
  };

  return (
    <div className="h-full">
      <div className="p-3">
        <Button OnClick={onExit}>
          <div className="border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black border-2 px-4 py-2 text-xl">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className="ml-2">Save</span>
          </div>
        </Button>
      </div>
      <h3 className="font-bold text-4xl text-center self-center align-middle">
        Custom Words
      </h3>

      <span className="block text-center mb-2">Add in your own words</span>

      <div className="flex justify-between px-2 mt-10">
        <button
          onClick={exportJSON}
          className="border-2 px-3 border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black"
        >
          Export
        </button>

        <button
          onClick={() => {
            importInputRef.current.click();
          }}
          className="px-3 border-2 border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black"
        >
          Import
        </button>
        <input
          onChange={importJSON}
          ref={importInputRef}
          id="custom-word-file"
          type="file"
          className="hidden"
        />
      </div>
      <div className="p-4 overflow-y-scroll flex flex-col justify-center w-full gap-y-4 mt-2">
        {textFields.map((fieldObj, index) => (
          <div key={`field-${index}`} className="flex gap-x-3 w-full">
            <input
              onChange={({ target }) => {
                onChangeWord(target.value, fieldObj.index);
              }}
              value={fieldObj.word}
              type="text"
              placeholder="Word"
              className="border-DTC_Yellow bg-DTC_Yellow text-black placeholder-gray-700 font-black text-base text-center h-10  w-2/5 px-2"
            />
            <input
              onChange={({ target }) => {
                onChangeImageURL(target.value, fieldObj.index);
              }}
              value={fieldObj.imageURL}
              type="text"
              placeholder="Image URL (Optional)"
              className="border-DTC_Yellow bg-DTC_Yellow text-black placeholder-gray-700 font-bold text-base text-center h-10 w-3/5 px-2 "
            />
            <button
              onClick={() => {
                deleteField(fieldObj.index);
              }}
            >
              <FontAwesomeIcon
                className="text-red-600 hover:text-red-700"
                icon={faTrash}
              />
            </button>
          </div>
        ))}
        <button
          className="text-center mx-auto text-xl border-2 px-4 py-1 border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black"
          onClick={addField}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );
};

CustomWords.propTypes = {
  setPanel: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
};

const parseOldMarkdown = (text, maxCharacterCount) => {
  let word_line = text.split("::");
  let processedWords = [];

  for (let index in word_line) {
    if (word_line[index] == "" || word_line[index] == "\n") {
      word_line.splice(index, 0);
    } else {
      let split_line = word_line[index].split(">");
      let clean_word = DOMPurify.sanitize(split_line[0].trim());

      if (clean_word !== "") {
        let img_link = "";
        clean_word = clean_word.replace(/\s+/g, " ");

        if (split_line[1] != null) {
          img_link = split_line[1].trim();
        }
        let clean_img = DOMPurify.sanitize(img_link);
        if (clean_word.length > maxCharacterCount) {
          clean_word = clean_word.substring(0, maxCharacterCount);
        }
        processedWords.push({ word: clean_word, imageURL: clean_img });
      }
    }
  }
  return processedWords;
};

const getOldSaveText = () => {
  const text = localStorage.getItem("custom_word_text_data");
  if (text != null) {
    localStorage.removeItem("custom_word_text_data");
    return text;
  }

  return null;
};

export default CustomWords;

import React from "react";
import { GameContext } from "../../game/store";
import { GameStateEnum } from "../../game/gameEnums.mjs";
import TimerHeadless from "../utils/TimerHeadless";
import TicksSound from "../../sounds/ticks.mp3";

const StartDelayTimer = () => {
  const gameContext = React.useContext(GameContext);

  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    setActive(false);
    if (!gameContext.state.wordPicked) {
      return;
    }
    if (gameContext.state.gameState === GameStateEnum.RoundStart) {
      gameContext.dispatch({ type: "RoundStarting" });
      return;
    }

    if (gameContext.state.gameState !== GameStateEnum.RoundStarting) {
      return;
    }
    setActive(true);
  }, [gameContext.state.wordPicked, gameContext.state.gameState]);

  return (
    active && (
      <div className="text-3xl text-gray-200 font-bold">
        <span>Round Starting...</span>{" "}
        <TimerHeadless
          onTick={(seconds) => {
            if (seconds <= 2) {
              const sfx = new Audio(TicksSound);
              sfx.volume = gameContext.state.settings.volume;
              sfx.play();
            }
          }}
          timeInSecs={gameContext.state.settings.startDelay}
          onEnd={() => {
            gameContext.dispatch({ type: "Play" });
          }}
        />
      </div>
    )
  );
};

export default StartDelayTimer;

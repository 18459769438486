import * as React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { client_id } from "../../api";
import { GameContext } from "../../game/store";
import { AuthTypeEnum } from "../../game/gameEnums.mjs";
import Button from "../utils/Button";
import DTCSlider from "../utils/DTCSlider";
import CheckBox from "../utils/CheckBox";

import { DataLayerPush } from "../../utils/browser_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../utils/Tooltip";

import LanguageDropdown from "./LanguageDropdown";
import NumberInput from "../utils/NumberInput";

const Settings = ({ setPanel }) => {
  const gameContext = React.useContext(GameContext);
  const [defualts, setDefualts] = React.useState(null);

  //init
  React.useEffect(() => {
    let defualts = {
      startDelay: gameContext.state.settings.startDelay,
      timeDurationSecs: gameContext.state.settings.timeDurationSecs,
      nonStrictCheck: gameContext.state.settings.nonStrictCheck,
      volume: gameContext.state.settings.volume,
      pauseOnEachRound: gameContext.state.settings.pauseOnEachRound,
      muteStreaks: gameContext.state.settings.muteStreaks,
      secondaryLang: gameContext.state.settings.secondaryLang,
    };
    let d = localStorage.getItem("Settings_Save");
    if (d) {
      let saveObj = JSON.parse(d);

      gameContext.dispatch({
        type: "UpdateSettings",
        payload: { ...saveObj },
      });

      defualts = {
        ...defualts,
        ...saveObj,
      };
      setDefualts(defualts);
    } else {
      setDefualts(defualts);
    }
  }, []);

  const OnBack = () => {
    let saveObj = {
      startDelay: gameContext.state.settings.startDelay,
      timeDurationSecs: gameContext.state.settings.timeDurationSecs,
      nonStrictCheck: gameContext.state.settings.nonStrictCheck,
      volume: gameContext.state.settings.volume,
      pauseOnEachRound: gameContext.state.settings.pauseOnEachRound,
      muteStreaks: gameContext.state.settings.muteStreaks,
      secondaryLang: gameContext.state.settings.secondaryLang,
    };
    localStorage.setItem("Settings_Save", JSON.stringify(saveObj));
    DataLayerPush("settings_save", saveObj);
    setPanel("MainMenu");
  };

  const Reset = () => {
    localStorage.clear();
    axios({
      method: "post",
      url: `https://id.twitch.tv/oauth2/revoke?client_id=${client_id}&token=${gameContext.state.user.access_token}`,
    })
      .then(() => {
        location.reload();
      })
      .catch(() => {
        location.reload();
      });
  };

  return React.useMemo(() => {
    return (
      <div className="p-3 h-full">
        <Button OnClick={OnBack}>
          <div className="text-xl px-4 py-2 border-2 border-DTC_Yellow p-3 hover:bg-DTC_Yellow hover:text-black">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className="ml-2">Save</span>
          </div>
        </Button>
        <h3 className="font-bold mt-2 text-4xl text-center self-center align-middle">
          Settings
        </h3>

        {defualts && (
          <div className="mt-6 p-4 sm:p-8">
            <div className="my-2">
              <span className="select-none mr-5" htmlFor="timerInput">
                Timer:
              </span>
              <NumberInput
                minValue={1}
                defualtValue={defualts.timeDurationSecs}
                onChange={(e) => {
                  if (e.target.value.length !== 0) {
                    gameContext.dispatch({
                      type: "UpdateSettings",
                      payload: { timeDurationSecs: parseInt(e.target.value) },
                    });
                  } else {
                    gameContext.dispatch({
                      type: "UpdateSettings",
                      payload: { timeDurationSecs: 1 },
                    });
                  }
                }}
              />
            </div>
            <div className="my-2">
              <span className="select-none mr-5" htmlFor="timerInput">
                Round Start Delay:
              </span>
              <NumberInput
                minValue={0}
                defualtValue={defualts.startDelay}
                onChange={(e) => {
                  if (e.target.value.length !== 0) {
                    gameContext.dispatch({
                      type: "UpdateSettings",
                      payload: { startDelay: parseInt(e.target.value) },
                    });
                  } else {
                    gameContext.dispatch({
                      type: "UpdateSettings",
                      payload: { startDelay: 0 },
                    });
                  }
                }}
              />
            </div>
            <div className="my-2">
              <CheckBox
                tooltip={
                  <>
                    <p>Allows the following to be optional:</p>
                    <p>
                      {
                        "'The', ' 's ' , '-', '&', `.`, `?`, `!`, accents and more"
                      }
                    </p>
                    <p>{"Does not do: 'of the' and 'and the' phrases."}</p>
                  </>
                }
                label={"Non-Strict Word Check"}
                defualtValue={defualts.nonStrictCheck}
                onChange={(value) => {
                  gameContext.dispatch({
                    type: "UpdateSettings",
                    payload: { nonStrictCheck: value },
                  });
                }}
              />
            </div>

            <div className="my-3">
              <CheckBox
                label={"Pause On Each Round"}
                defualtValue={defualts.pauseOnEachRound}
                onChange={(value) => {
                  gameContext.dispatch({
                    type: "UpdateSettings",
                    payload: { pauseOnEachRound: value },
                  });
                }}
              />
            </div>

            <div className="my-3">
              <CheckBox
                label={"Mute Streaks Sound Effect"}
                defualtValue={defualts.muteStreaks}
                onChange={(value) => {
                  gameContext.dispatch({
                    type: "UpdateSettings",
                    payload: { muteStreaks: value },
                  });
                }}
              />
            </div>

            <div className="my-2 flex gap-x-4">
              <div>
                <Tooltip tip="Show certain word's translation or localization of selected language">
                  <span> Secondary Language</span>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size="sm"
                    className="ml-1 mb-1"
                  />
                </Tooltip>
              </div>
              <LanguageDropdown />
            </div>

            <div className="my-2">
              <span className="select-none">Volume:</span>
              <DTCSlider
                ariaLabel="Volume Slider"
                defaultValue={defualts.volume * 100}
                onChange={(v) => {
                  let volume = Math.floor(Math.log10(v) * 50) / 100;
                  if (volume === -Infinity) {
                    volume = 0;
                  }
                  gameContext.dispatch({
                    type: "UpdateSettings",
                    payload: { volume: volume },
                  });
                }}
              />
            </div>

            <div className="mt-14 flex flex-col gap-4 items-center justify-center">
              <div>
                <Button label="Reset Game Settings" OnClick={Reset}>
                  <span className="p-2 text-red-400">Reset Game</span>
                </Button>
              </div>
              {gameContext.state.user.authType === AuthTypeEnum.Twitch && (
                <div>
                  <a
                    href="https://www.twitch.tv/settings/connections"
                    label="Disconnect"
                    aria-label="Disconnect"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="p-2 text-red-400">Disconnect</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }, [gameContext.state.settings, defualts, gameContext.state.user.authType]);
};

Settings.propTypes = {
  setPanel: PropTypes.func.isRequired,
};

export default Settings;

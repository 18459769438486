import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Listbox } from "@headlessui/react";
import { GameContext } from "../../game/store";

const LanguageDropdown = () => {
  const gameContext = React.useContext(GameContext);
  const [supportedLangs] = React.useState(
    gameContext.state.supporedSecondaryLangs
  );
  const [langsListed, setLangsListed] = React.useState([]);

  React.useEffect(() => {
    const userLang = (
      navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage
    ).split("-")[0];

    //setup listed langs for dropdown
    let newLangsListed = [{ lang: "none", label: "None" }];
    let selectedLang = null;
    newLangsListed = newLangsListed.concat(
      supportedLangs.map((langObj) => {
        if (langObj.lang === userLang) {
          selectedLang = langObj;
        }
        return { ...langObj };
      })
    );
    setLangsListed(newLangsListed);

    if (selectedLang) {
      gameContext.dispatch({
        type: "UpdateLanguages",
        payload: {
          secondaryLang: selectedLang,
        },
      });
    }
  }, []);

  const onSelect = (langObj) => {
    gameContext.dispatch({
      type: "UpdateLanguages",
      payload: {
        secondaryLang: langObj,
      },
    });
  };

  return (
    <Listbox
      as="div"
      value={gameContext.state.settings.secondaryLang}
      onChange={onSelect}
      className="relative w-2/4"
    >
      {({ open }) => (
        <>
          <Listbox.Button className="px-3 py-1 w-full border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black border-2 flex justify-center items-center">
            <span className="w-4/5">
              {gameContext.state.settings.secondaryLang.label}
            </span>
            <span className="w-1/5">
              <FontAwesomeIcon icon={faChevronDown} rotation={open ? 180 : 0} />
            </span>
          </Listbox.Button>
          {open && (
            <div className="z-10 absolute w-full bg-DTC_DarkBlue border">
              <Listbox.Options>
                {langsListed.map((langObj, index) => (
                  <Listbox.Option
                    className="p-2 cursor-pointer w-full text-center border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black"
                    key={`langObj-${index}`}
                    value={langObj}
                  >
                    {langObj.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          )}
        </>
      )}
    </Listbox>
  );
};

export default LanguageDropdown;

import * as React from "react";
import cryptoRandomString from "crypto-random-string";
import { GameContext } from "../../game/store";
import {
  AuthTypeEnum,
  ErrorLevelEnum,
  GameStateEnum,
} from "../../game/gameEnums.mjs";
import {
  client_id,
  redirect_uri,
  bypassChatRequirement,
  debugChannel,
} from "../../api";
import Button from "../utils/Button";
import Spinner from "../utils/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import NewWindow from "../utils/NewWindow";
import {
  getTwitchChannelData,
  getTwitchChannelIDByDisplayName,
} from "../../utils/browser_utils";
import tmi from "tmi.js";
import { Disclosure } from "@headlessui/react";

const SignIn = () => {
  const gameContext = React.useContext(GameContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showWindow, setShowWindow] = React.useState(false);
  const [hasPostedAuth, setHasPostedAuth] = React.useState(false);
  const [channelName, setChannelName] = React.useState("");
  const [disclaimer, setDisclaimer] = React.useState({
    display: false,
    jsx: null,
  });
  const windowRef = React.useRef();
  const channelNameRef = React.useRef();
  channelNameRef.current = channelName;

  React.useEffect(() => {
    window.PostAuthData = (hash) => {
      setHasPostedAuth(true);
      setShowWindow(false);
      OnAuthTwitch(hash);
    };
    if (bypassChatRequirement) {
      bypassAuth();
    }
  }, []);

  if (typeof window !== "undefined") {
    window.onbeforeunload = function () {
      if (showWindow) {
        windowRef.current.window.close();
      }
    };
  }

  //convert hash to a useable object structure
  const parseHash = (hash) => {
    hash = hash.substr(1);
    let hashData = hash
      .split("&")
      .map((v) => v.split("="))
      .reduce(
        (pre, [key, value]) => ({
          ...pre,
          [key]: value,
        }),
        {}
      );
    return hashData;
  };

  const checkState = (state) => {
    let local_state = null;
    try {
      local_state = localStorage.getItem("state");
    } catch (err) {
      console.warn(err);
    }

    if (local_state && state) {
      if (local_state == state) {
        return true;
      } else {
        console.error("mismatch states");
      }
    } else {
      console.error("state null");
    }
    return false;
  };

  const bypassAuth = () => {
    gameContext.dispatch({
      type: "Authed",
      payload: {
        user: {
          id: 0,
          access_token: null,
          login: "TESTUSER",
          display_name: "TESTUSER",
          authType: AuthTypeEnum.Twitch,
        },
      },
    });
  };

  const OnAuthTwitch = (hash) => {
    let hashData = parseHash(hash);
    if (hashData && checkState(hashData.state) && hashData.access_token) {
      //Get user data
      getTwitchChannelData(
        hashData.access_token,
        client_id,
        debugChannel.length > 0 ? debugChannel : null
      )
        .then((res) => {
          console.log("User Authed and Verified");

          const data = res;
          // console.log(data);

          getTwitchChannelIDByDisplayName(
            hashData.access_token,
            client_id,
            data.display_name
          )
            .then((idObj) => {
              gameContext.dispatch({
                type: "Authed",
                payload: {
                  user: {
                    id: idObj.id,
                    access_token: hashData.access_token,
                    login: data.login,
                    display_name: data.display_name,
                    authType: AuthTypeEnum.Twitch,
                    viewer_count: res.viewer_count,
                  },
                },
              });
            })
            .catch(() => {
              signInFailed("Try Refreshing the page.");
            });
        })
        .catch((e) => {
          signInFailed(e);
        });
    } else {
      setHasPostedAuth(false);
      setIsLoading(false);
    }
  };

  const signInFailed = (e) => {
    console.warn(e);
    setHasPostedAuth(false);
    setIsLoading(false);
    gameContext.dispatch({
      type: "DisplayError",
      payload: {
        msg: `Was not able to sign you in. ${e}`,
        errorLevel: ErrorLevelEnum.ERROR,
      },
    });
  };

  const onWindowUnload = () => {
    if (!hasPostedAuth) {
      setIsLoading(false);
    }
    setShowWindow(false);
  };

  const onTwitchChannelName = (channel_name) => {
    const tmi_client = new tmi.Client({
      options: {
        debug: false,
        skipUpdatingEmotesets: true,
        skipMembership: true,
      },
      client_id: client_id,
    });

    tmi_client.connect().catch((err) => {
      gameContext.dispatch({
        type: "DisplayError",
        payload: {
          msg: `Could not connect to Twitch servers. ${err}`,
          errorLevel: ErrorLevelEnum.ERROR,
        },
      });
      setIsLoading(false);
    });

    tmi_client.on("connected", () => {
      tmi_client
        .join(channel_name)
        .then(() => {
          gameContext.dispatch({
            type: "Authed",
            payload: {
              user: {
                id: null,
                access_token: null,
                login: channel_name,
                display_name: channel_name,
                authType: AuthTypeEnum.TwitchChannelName,
              },
            },
          });
          tmi_client.disconnect();
        })
        .catch(() => {
          setIsLoading(false);
          tmi_client.disconnect();
          gameContext.dispatch({
            type: "DisplayError",
            payload: {
              msg: "Failed to connect. Make sure you entered your channel name correctly",
              errorLevel: ErrorLevelEnum.INFO,
            },
          });
        });
    });
  };

  const onWindowBlock = () => {
    gameContext.dispatch({
      type: "DisplayError",
      payload: {
        msg: "Could not open sign in window. Make sure to allow popups for this site.",
        errorLevel: ErrorLevelEnum.ERROR,
      },
    });
  };

  const displayDisclaimer = () => {
    return (
      <div className="spawn mx-auto">
        {disclaimer.jsx}
        <button
          className="inline-flex justify-center px-4 py-2 font-medium bg-glass rounded-sm mt-2"
          onClick={() => {
            setDisclaimer({ display: false });
          }}
        >
          Back
        </button>
      </div>
    );
  };

  return (
    <div style={{ minHeight: "70px" }}>
      {showWindow && (
        <>
          <NewWindow
            ref={windowRef}
            url={`https://id.twitch.tv/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=token&scope=chat:read&state=${localStorage.getItem(
              "state"
            )}`}
            title={"Da Stream Charades - Sign In"}
            features={{
              width: "760px",
              height: "450px",
              menubar: "no",
              location: "no",
              directories: "no",
              status: "no",
              resizable: "no",
              popup: "yes",
            }}
            onBlock={() => {
              onWindowBlock();
            }}
            onUnload={() => {
              onWindowUnload();
            }}
          ></NewWindow>
        </>
      )}
      {isLoading || gameContext.state.gameState >= GameStateEnum.Authed ? (
        <Spinner size="3rem" />
      ) : (
        <>
          {disclaimer.display ? (
            displayDisclaimer()
          ) : (
            <div className="w-13">
              <h3 className="font-bold text-blue-100 text-3xl m-6">
                Connect your account to play!
              </h3>
              <div className="w-13">
                <Button
                  label="Connect with Twitch"
                  OnClick={() => {
                    let state = cryptoRandomString({
                      length: 25,
                      type: "url-safe",
                    });
                    try {
                      localStorage.setItem("state", state);
                      setIsLoading(true);
                      setShowWindow(true);
                    } catch (err) {
                      setIsLoading(false);
                      setShowWindow(false);
                      gameContext.dispatch({
                        type: "DisplayError",
                        payload: {
                          msg: `Your browser needs to allow for cookies/localstorage. Will not sign in other wise. ${err}`,
                          errorLevel: ErrorLevelEnum.ERROR,
                        },
                      });
                    }
                  }}
                >
                  <div className="bg-TwitchPurple h-12 flex gap-2 w-44 justify-center items-center">
                    <FontAwesomeIcon
                      icon={faTwitch}
                      style={{ fontSize: "1.6rem" }}
                    />

                    <div className="text-xl font-bold">Connect</div>
                  </div>
                </Button>
              </div>
              <div className="my-1 font-bold text-gray-300">
                <Disclosure defaultOpen={false}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="py-2">
                        Other Options{" "}
                        {open ? (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            style={{ fontSize: "1rem" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faChevronUp}
                            style={{ fontSize: "1rem" }}
                          />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel>
                        <div className="w-13 mt-1">
                          <Button
                            label="Connect with Twitch Using Channel Name"
                            OnClick={() => {
                              try {
                                localStorage.setItem("state", "state");
                                setDisclaimer({
                                  display: true,
                                  jsx: (
                                    <div className="">
                                      <h3 className="text-xl font-bold tracking-wider bg-gray-900 rounded-md  p-2">
                                        Connecting using a channel name
                                        <br />
                                        will prevent certain features from
                                        working.
                                      </h3>
                                      <input
                                        className="h-8 p-2 py-4 pl-2 border bg-gray-600 max-w-md mt-4 text-center text-xl"
                                        type="text"
                                        name="channel name"
                                        aria-label="channel name"
                                        placeholder="Channel Name"
                                        spellCheck={false}
                                        onChange={(e) => {
                                          setChannelName(e.target.value);
                                        }}
                                      />

                                      <button
                                        className="bg-TwitchPurple py-2 px-2 block mt-5 mx-auto font-bold"
                                        aria-label="Enter Channel Name"
                                        onClick={() => {
                                          setIsLoading(true);
                                          onTwitchChannelName(
                                            channelNameRef.current
                                          );
                                        }}
                                      >
                                        Ok, Enter
                                      </button>
                                    </div>
                                  ),
                                });
                              } catch (err) {
                                gameContext.dispatch({
                                  type: "DisplayError",
                                  payload: {
                                    msg: `Your browser needs to allow for cookies/localstorage. Will not sign in other wise. ${err}`,
                                    errorLevel: ErrorLevelEnum.ERROR,
                                  },
                                });
                              }
                            }}
                          >
                            <div className="bg-gray-500 py-2 px-2 flex gap-2 items-center justify-center text-sm">
                              <div style={{ fontSize: "1.3rem" }}>
                                <FontAwesomeIcon icon={faTwitch} />
                              </div>
                              <div className="font-bold">Channel Name</div>
                            </div>
                          </Button>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SignIn;

import { GameStateEnum, ErrorTypeEnum } from "./gameEnums";

import {
  CalcVolume,
  GetAuthTypeString,
  DataLayerPush,
} from "../utils/browser_utils";
import RoundStartAudio from "../sounds/round_start.wav";
import RoundLostAudio from "../sounds/round_lost.wav";
import StartSound from "../sounds/start.mp3";

//Action comes in as a object {type, payload}
const reducer = (state, action) => {
  switch (action.type) {
    case "Authed":
      DataLayerPush("user_authed", {
        auth_type: GetAuthTypeString(action.payload.user.authType),
        display_name: action.payload.user.display_name,
        viewer_count: action.payload.user.viewer_count,
      });
      return {
        ...state,
        gameState: GameStateEnum.Authed,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    case "ChatConnected":
      return {
        ...state,
        gameState: GameStateEnum.ChatConnected,
        chatConnected: true,
        chatInterface: action.payload.chatInterface,
      };
    case "ChatDisconnected":
      return {
        ...state,
        chatConnected: false,
      };
    case "WordListUpdate":
      return { ...state, wordListPrimed: action.payload.wordListPrimed };
    case "StartRound":
      document.body.classList.add("bg-animate");
      RoundNextSound(state.settings.volume);
      return {
        ...state,
        gameState: GameStateEnum.RoundStart,
        rounds: state.rounds + 1,
      };
    case "RoundStarting":
      return { ...state, gameState: GameStateEnum.RoundStarting };
    case "WordPicked":
      return {
        ...state,
        wordPicked: true,
        wordInPlay: {
          ...action.payload,
          height: action.payload.height ? action.payload.height : 0,
          width: action.payload.width ? action.payload.width : 0,
        },
      };
    case "Play":
      RoundStartSound(state.settings.volume);
      return {
        ...state,
        gameState: state.settings.pauseOnEachRound
          ? GameStateEnum.Paused
          : GameStateEnum.Playing,
      };
    case "Unpause":
      return { ...state, gameState: GameStateEnum.Playing };
    case "Pause":
      DataLayerPush("round_paused", {
        word: state.wordInPlay.word,
        alt_words: state.wordInPlay.altWords,
        category_name: state.wordInPlay.categoryName.name,
        seconds_left: state.wordInPlay.secondsLeft,
        seconds_set: state.settings.timeDurationSecs,
      });
      return { ...state, gameState: GameStateEnum.Paused };
    case "Lost":
      DataLayerPush("round_lost", {
        word: state.wordInPlay.word,
        alt_words: state.wordInPlay.altWords,
        category_name: state.wordInPlay.categoryName.name,
        seconds_left: state.wordInPlay.secondsLeft,
        seconds_set: state.settings.timeDurationSecs,
      });
      RoundLostSound(state.settings.volume);
      return {
        ...state,
        wordPicked: false,
        gameState: GameStateEnum.Lost,
        streak: 0,
      };
    case "Won":
      state.playWinAnimation();
      DataLayerPush("round_won", {
        word: state.wordInPlay.word,
        alt_words: state.wordInPlay.altWords,
        category_name: state.wordInPlay.categoryName.name,
        seconds_left: state.wordInPlay.secondsLeft,
        seconds_set: state.settings.timeDurationSecs,
      });
      return {
        ...state,
        wordPicked: false,
        roundsWon: state.roundsWon + 1,
        gameState: GameStateEnum.Won,
        streak:
          state.settings.muteStreaks === false
            ? state.streak >= state.settings.maxStreak
              ? state.settings.maxStreak
              : ++state.streak
            : 0,
      };
    case "SecondsTick":
      return {
        ...state,
        wordInPlay: {
          ...state.wordInPlay,
          secondsLeft: action.payload.seconds,
        },
      };
    case "UpdateLanguages":
      return {
        ...state,
        settings: {
          ...state.settings,
          secondaryLang: action.payload.secondaryLang,
        },
      };
    case "UpdateShowSidePanel":
      return { ...state, showSidePanel: action.payload.showSidePanel };
    case "UpdateSettings":
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
      };
    case "FailedLocalStorage":
      return {
        ...state,
        settings: { ...state.settings, canDoLocalStorage: false },
      };
    case "DisplayError":
      DataLayerPush("error_displayed", {
        error_level: action.payload.errorLevel,
        error_msg: action.payload.msg,
        error_type: action.payload.errorType
          ? action.payload.errorType
          : ErrorTypeEnum.Undefined,
      });
      return {
        ...state,
        errorInfo: {
          displayError: true,
          errorLevel: action.payload.errorLevel,
          msg: action.payload.msg,
          errorType: action.payload.errorType
            ? action.payload.errorType
            : ErrorTypeEnum.Undefined,
        },
      };
    case "CloseError":
      return {
        ...state,
        errorInfo: {
          ...state.errorInfo,
          displayError: false,
        },
      };
    case "SetWinAnimation":
      return { ...state, playWinAnimation: action.payload.winAnimation };
    case "UpdateScoreList":
      return { ...state, scoreList: action.payload.scoreList };
    case "UpdateRounds":
      return { ...state, rounds: action.payload.rounds };
    case "UpdateRoundsWon":
      return { ...state, roundsWon: action.payload.roundsWon };
    default:
      console.warn("No Action Type Found!");
      return state;
  }
};

//check when ther enot delay
const RoundStartSound = (master_voume) => {
  let roundAudio = new Audio(StartSound);
  roundAudio.volume = CalcVolume(master_voume);
  roundAudio.play();
};

const RoundNextSound = (master_voume) => {
  let roundAudio = new Audio(RoundStartAudio);
  roundAudio.volume = CalcVolume(master_voume);
  roundAudio.play();
};

const RoundLostSound = (master_voume) => {
  let roundAudio = new Audio(RoundLostAudio);
  roundAudio.volume = CalcVolume(master_voume);
  roundAudio.play();
};

export default reducer;

import * as React from "react";
import { GameContext } from "../../game/store";
import Particles from "react-tsparticles";
import cheer3 from "../../sounds/children-hooray_2.wav";
import cheer2 from "../../sounds/children-hooray.wav";
import cheer1 from "../../sounds/children-hooray_3.wav";
import streak1 from "../../sounds/streak-1.mp3";
import streak2 from "../../sounds/streak-2.mp3";
import streak3 from "../../sounds/streak-3.mp3";
import streak4 from "../../sounds/streak-4.mp3";
import streak5 from "../../sounds/streak-5.mp3";
import { CalcVolume, getRandomInt } from "../../utils/browser_utils";

class WinAnimation extends React.PureComponent {
  static contextType = GameContext;
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.streaksAudio = [
      new Audio(streak1),
      new Audio(streak2),
      new Audio(streak3),
      new Audio(streak4),
      new Audio(streak5),
    ];
  }

  componentDidMount() {
    this.context.dispatch({
      type: "SetWinAnimation",
      payload: { winAnimation: this.playAnmation.bind(this) },
    });
  }

  particlesLoaded = (c) => {
    this.container.current = c;
  };

  getStreakSound = () => {
    if (
      this.context.state.streak > 0 &&
      this.context.state.settings.muteStreaks === false
    ) {
      return this.streaksAudio[this.context.state.streak - 1];
    } else {
      return null;
    }
  };

  playAnmation = () => {
    const cheers = [cheer1, cheer2, cheer3];
    let cheerAudio = new Audio(cheers[getRandomInt(0, cheers.length - 1)]);

    let streakAudio = this.getStreakSound();
    cheerAudio.volume = CalcVolume(this.context.state.settings.volume);
    cheerAudio.play();
    if (streakAudio) {
      streakAudio.volume = CalcVolume(this.context.state.settings.volume);
      setTimeout(() => {
        streakAudio.play();
      }, 200);
    }
    this.container.current.playEmitter("conOne");
    this.container.current.playEmitter("conTwo");
    this.container.current.playEmitter("conThree");
    setTimeout(() => {
      this.container.current.pauseEmitter("conOne");
      this.container.current.pauseEmitter("conTwo");
      this.container.current.pauseEmitter("conThree");
    }, 400);
  };

  render() {
    return (
      <>
        {/* <button
          onClick={() => {
            this.playAnmation();
          }}
        >
          Play
        </button> */}
        <Particles
          id="tsparticles"
          loaded={this.particlesLoaded}
          options={{
            autoPlay: true,
            background: {
              image: "",
              position: "",
              repeat: "",
              size: "",
              opacity: 1,
            },
            fullScreen: {
              enable: true,
              zIndex: 999,
            },
            detectRetina: true,
            duration: 0,
            fpsLimit: 60,
            manualParticles: [],
            motion: {
              disable: false,
              reduce: {
                factor: 4,
                value: true,
              },
            },
            particles: {
              collisions: {
                bounce: {
                  horizontal: {
                    random: {
                      enable: false,
                      minimumValue: 0.1,
                    },
                    value: 1,
                  },
                  vertical: {
                    random: {
                      enable: false,
                      minimumValue: 0.1,
                    },
                    value: 1,
                  },
                },
                enable: false,
                mode: "bounce",
                overlap: {
                  enable: true,
                  retries: 0,
                },
              },
              color: {
                value: [
                  "#18ff0b",
                  "#6b8e23",
                  "#ffd700",
                  "#ffc0cb",
                  "#6a5acd",
                  "#add8e6",
                  "#ee82ee",
                  "#98fb98",
                  "#ffffff",
                  "#f4a460",
                  "#d2691e",
                  "#dc143c",
                ],
                animation: {
                  h: {
                    count: 0,
                    enable: true,
                    offset: 0,
                    speed: 30,
                    sync: true,
                  },
                  s: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    sync: true,
                  },
                  l: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    sync: true,
                  },
                },
              },
              destroy: {
                mode: "none",
                split: {
                  count: 1,
                  factor: {
                    random: {
                      enable: false,
                      minimumValue: 0,
                    },
                    value: 3,
                  },
                  rate: {
                    random: {
                      enable: false,
                      minimumValue: 0,
                    },
                    value: {
                      min: 4,
                      max: 9,
                    },
                  },
                  sizeOffset: true,
                },
              },
              gradient: [],
              groups: {},
              life: {
                count: 0,
                delay: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 0,
                  sync: false,
                },
                duration: {
                  random: {
                    enable: false,
                    minimumValue: 0.0001,
                  },
                  value: 0,
                  sync: false,
                },
              },
              links: {
                blink: false,
                color: {
                  value: "#fff",
                },
                consent: false,
                distance: 100,
                enable: false,
                frequency: 1,
                opacity: 1,
                shadow: {
                  blur: 5,
                  color: {
                    value: "#00ff00",
                  },
                  enable: false,
                },
                triangles: {
                  enable: false,
                  frequency: 1,
                },
                width: 1,
                warp: false,
              },
              move: {
                angle: {
                  offset: 0,
                  value: 90,
                },
                attract: {
                  distance: 200,
                  enable: false,
                  rotate: {
                    x: 3000,
                    y: 3000,
                  },
                },
                decay: 0.1,
                distance: {},
                direction: "bottom",
                drift: 0,
                enable: true,
                gravity: {
                  acceleration: 11.81,
                  enable: true,
                  inverse: false,
                  maxSpeed: 200,
                },
                path: {
                  clamp: true,
                  delay: {
                    random: {
                      enable: false,
                      minimumValue: 0,
                    },
                    value: 0,
                  },
                  enable: false,
                  options: {},
                },
                outModes: {
                  default: "destroy",
                  bottom: "destroy",
                  left: "bounce",
                  right: "bounce",
                  top: "none",
                },
                random: false,
                size: false,
                speed: {
                  min: 50,
                  max: 150,
                },
                spin: {
                  acceleration: 0,
                  enable: false,
                },
                straight: false,
                trail: {
                  enable: false,
                  length: 10,
                  fillColor: {
                    value: "#000000",
                  },
                },
                vibrate: false,
                warp: false,
              },
              number: {
                density: {
                  enable: false,
                  area: 800,
                  factor: 1000,
                },
                limit: 300,
                value: 0,
              },
              opacity: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
                animation: {
                  count: 0,
                  enable: false,
                  speed: 0.3,
                  sync: true,
                  destroy: "min",
                  startValue: "max",
                },
              },
              orbit: {
                animation: {
                  count: 0,
                  enable: false,
                  speed: 1,
                  sync: false,
                },
                enable: false,
                opacity: 1,
                rotation: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 45,
                },
                width: 1,
              },
              reduceDuplicates: false,
              repulse: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
                enabled: false,
                distance: 1,
                duration: 1,
                factor: 1,
                speed: 1,
              },
              // roll: {
              //   darken: {
              //     enable: true,
              //     value: 20,
              //   },
              //   enable: true,
              //   enlighten: {
              //     enable: true,
              //     value: 30,
              //   },
              //   mode: "vertical",
              //   speed: {
              //     min: 5,
              //     max: 10,
              //   },
              // },
              rotate: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: {
                  min: 0,
                  max: 360,
                },
                animation: {
                  enable: true,
                  speed: 40,
                  sync: false,
                },
                direction: "random",
                path: false,
              },
              shadow: {
                blur: 0,
                color: {
                  value: "#000000",
                },
                enable: false,
                offset: {
                  x: 0,
                  y: 0,
                },
              },
              shape: {
                options: {
                  polygon: [
                    {
                      sides: 3,
                    },
                    {
                      sides: 5,
                    },
                  ],
                },
                type: ["square", "polygon"],
              },
              size: {
                random: {
                  enable: true,
                  minimumValue: 5,
                },
                value: 8,
                animation: {
                  count: 0,
                  enable: true,
                  speed: 5,
                  sync: false,
                  destroy: "none",
                  startValue: "random",
                },
              },
              stroke: {
                width: 0,
              },
              tilt: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: {
                  min: 0,
                  max: 60,
                },
                animation: {
                  enable: true,
                  speed: 40,
                  sync: false,
                },
                direction: "random",
                enable: true,
              },
              twinkle: {
                lines: {
                  enable: false,
                  frequency: 0.05,
                  opacity: 1,
                },
                particles: {
                  enable: false,
                  frequency: 0.05,
                  opacity: 1,
                },
              },
              wobble: {
                distance: 30,
                enable: true,
                speed: {
                  min: -10,
                  max: 10,
                },
              },
              zIndex: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
                opacityRate: 1,
                sizeRate: 1,
                velocityRate: 1,
              },
            },
            pauseOnBlur: true,
            pauseOnOutsideViewport: true,
            responsive: [],
            themes: [],
            zLayers: 100,
            emitters: [
              {
                name: "conOne",
                autoPlay: false,
                fill: true,
                life: {
                  wait: false,
                },
                rate: {
                  quantity: 10,
                  delay: 0.1,
                },
                shape: "square",
                startCount: 0,
                size: {
                  mode: "percent",
                  height: 0,
                  width: 0,
                },
                position: {
                  x: 0,
                  y: -50,
                },
              },
              {
                name: "conTwo",
                autoPlay: false,
                fill: true,
                life: {
                  wait: false,
                },
                rate: {
                  quantity: 10,
                  delay: 0.1,
                },
                shape: "square",
                startCount: 0,
                size: {
                  mode: "percent",
                  height: 0,
                  width: 0,
                },
                position: {
                  x: 50,
                  y: -50,
                },
              },
              {
                name: "conThree",
                autoPlay: false,
                fill: true,
                life: {
                  wait: false,
                },
                rate: {
                  quantity: 10,
                  delay: 0.1,
                },
                shape: "square",
                startCount: 0,
                size: {
                  mode: "percent",
                  height: 0,
                  width: 0,
                },
                position: {
                  x: 100,
                  y: -50,
                },
              },
            ],
          }}
        />
      </>
    );
  }
}

export default WinAnimation;

import { DynamicCategory } from "./dynamic_category";
import { AuthTypeEnum, FiltersEnum } from "../gameEnums.mjs";
import {
  getBTTVEmotes,
  getFFZEmotes,
  getSubEmotes,
  get7TVEmotes,
} from "./emotes_fetch";

export class WebCategory extends DynamicCategory {
  /**
   *
   * @param name Name of category to display
   * @param fetch Callback func when getting words. Must return a promise with a reslove value structure of  { words: Array } or a reject with { displayError: bool, msg: String }
   * pass with a param of userData
   * @param filter To be grouped with other categories. Must be type FiltersEnum
   * @param authTypes Array of supported auth types. [] means all
   */
  constructor(name, fetch, filter, authTypes) {
    super(name, "yes", fetch, filter, authTypes);
  }

  /**
   *
   * @param forceFetch If true will always run its fetch func, else return this.words if not null
   * @returns Promise: reslove(), reject({ displayError: bool, msg: String, errorLevel: ErrorLevelEnum })
   */
  getWords(forceFetch = false) {
    return new Promise((reslove, reject) => {
      if (this.words != null && !forceFetch) {
        reslove();
      } else {
        this.fetch(this.userData)
          .then((res) => {
            this.words = res.words;
            // console.log(this.words);
            reslove();
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  }
}

export let web_categories = [];

// web_categories.push(
//   new WebCategory(
//     "Web Tester",
//     (userData) => {
//       return new Promise((reslove) => {
//         console.log(userData);
//         setTimeout(() => {
//           reslove({
//             words: [
//               {
//                 word: "noooot4Head",
//                 imageURL: "https://static-cdn.jtvnw.net/emoticons/v1/354/3.0",
//               },
//             ],
//           });
//           // reject({displayError: true, msg: "Could not get web tester"});
//         }, 2000);
//       });
//     },
//     []
//   )
// );

web_categories.push(
  new WebCategory(
    "Your Subscription Emotes",
    getSubEmotes,
    FiltersEnum.YourChannel,
    [AuthTypeEnum.Twitch]
  )
);

web_categories.push(
  new WebCategory("Your 7TV Emotes", get7TVEmotes, FiltersEnum.YourChannel, [
    AuthTypeEnum.Twitch,
  ])
);

web_categories.push(
  new WebCategory("Your BTTV Emotes", getBTTVEmotes, FiltersEnum.YourChannel, [
    AuthTypeEnum.Twitch,
  ])
);

web_categories.push(
  new WebCategory("Your FFZ Emotes", getFFZEmotes, FiltersEnum.YourChannel, [
    AuthTypeEnum.Twitch,
  ])
);

import * as React from "react";
import PropTypes from "prop-types";
import { GameContext } from "../../../game/store";
import {
  GameStateEnum,
  FiltersEnum,
  FilterTitles,
} from "../../../game/gameEnums.mjs";
import CategorySwitch from "./CategorySwitch";
import {
  categories as static_categories,
  Category,
} from "../../../game/categories/categories.mjs";
import {
  web_categories,
  WebCategory,
} from "../../../game/categories/web_categories";
import {
  panel_categories,
  PanelCategory,
} from "../../../game/categories/panel_categories";
import Button from "../../utils/Button";
import {
  WordRegEx,
  RegExpEscape,
  ShuffleArray,
  DataLayerPush,
} from "../../../utils/browser_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

//Debugging
import { debugWordBank, skipShuffle } from "../../../api";
import ListImageView from "../../utils/Debug/ListImageView";
//---

const WordBank = ({ setPanel }) => {
  const gameContext = React.useContext(GameContext);
  const [wordList, setWordList] = React.useState({
    words: [],
    activeCats: 0,
  });
  const [pickingIndex, setPickingIndex] = React.useState(0);
  const [allCategories, setAllCategories] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");

  const init = () => {
    let cats = [];
    let authType = gameContext.state.user.authType;

    const onEachDynamicCat = (c) => {
      if (c.authTypes.includes(authType) || c.authTypes.length == 0) {
        c.onUserAuthed(gameContext.state.user);
        c.words = null;
        c.isOn = false;
        cats.push(c);
      }
    };

    cats = cats.concat(static_categories);
    web_categories.map(onEachDynamicCat);
    panel_categories.map(onEachDynamicCat);

    setAllCategories(cats);
    RefreshList(cats);
  };

  React.useEffect(() => {
    if (gameContext.state.gameState === GameStateEnum.RoundStart) {
      //setup wordInPlay
      let pick = wordList.words[pickingIndex];
      let wordCheck = [];

      if (pick.altWords) {
        const filteredAltWords = [];
        for (let index = 0; index < pick.altWords.length; index++) {
          let altWordObj = pick.altWords[index];
          const secondaryLangObj =
            gameContext.state.settings.secondaryLang.lang === altWordObj.lang
              ? gameContext.state.settings.secondaryLang
              : null;
          //Make lang shown as primary word, if it was not translated
          if (secondaryLangObj && !altWordObj.translated && !pick.wordSwapped) {
            let t = structuredClone(pick.word);
            pick.word = altWordObj.word;
            altWordObj = { word: t };
            pick.wordSwapped = true;
          }
          //filter all other langs
          if (!altWordObj.lang || secondaryLangObj) {
            filteredAltWords.push(altWordObj);
          }
        }
        pick.altWords = filteredAltWords;

        pick.altWords.map((altWord) => {
          wordCheck.push(
            WordRegEx(altWord.word, gameContext.state.settings.nonStrictCheck)
          );
        });
      }

      wordCheck.push(
        WordRegEx(pick.word, gameContext.state.settings.nonStrictCheck)
      );

      let newIndex = pickingIndex + 1;
      if (newIndex >= wordList.words.length) {
        newIndex = 0;
        setWordList({
          ...wordList,
          words: skipShuffle ? wordList.words : ShuffleArray(wordList.words),
        });
      }
      setPickingIndex(newIndex);

      gameContext.dispatch({
        type: "WordPicked",
        payload: {
          ...pick,
          wordCheck: wordCheck,
          altWords: pick.altWords ? pick.altWords : [],
        },
      });

      console.log("Word Picked");
    } else if (gameContext.state.gameState === GameStateEnum.Authed) {
      init();
    }
  }, [gameContext.state.gameState]);

  const RefreshList = (initCat = null) => {
    let newList = [];
    let newOnStates = 0;

    const onEach = (c) => {
      if (c.isOn) {
        newOnStates++;
        c.words.map((wObj) => {
          wObj.categoryName = { name: c.name, translate: c.translateTitle };
          newList.push(wObj);
        });
      }
    };

    if (initCat == null) {
      allCategories.forEach(onEach);
    } else {
      initCat.forEach(onEach);
    }

    if (!skipShuffle) {
      newList = ShuffleArray(newList);
    }

    if (newOnStates == 0) {
      gameContext.dispatch({
        type: "WordListUpdate",
        payload: {
          wordListPrimed: false,
        },
      });
    } else if (newOnStates > 0) {
      gameContext.dispatch({
        type: "WordListUpdate",
        payload: {
          wordListPrimed: true,
        },
      });
    }
    setWordList({ words: newList, activeCats: newOnStates });
    setPickingIndex(0);
  };

  const addCategory = (categoryObj) => {
    if (wordList.activeCats < gameContext.state.settings.maxSelections) {
      categoryObj.isOn = true;
      RefreshList();
    }
  };

  const removeCategory = (categoryObj) => {
    categoryObj.isOn = false;
    RefreshList();
  };

  const ClearAll = () => {
    allCategories.map((c) => {
      c.isOn = false;
    });
    RefreshList();
  };

  const CategoriesJSX = () => {
    return allCategories.map((c, index) => {
      const onChangeSwitch = (value) => {
        const newAllCategories = allCategories;
        if (value) {
          if (c instanceof WebCategory || c instanceof PanelCategory) {
            newAllCategories[index].isLoading = true;
            let getWords;
            if (c instanceof WebCategory) {
              getWords = () => {
                return c.getWords();
              };
            } else if (c instanceof PanelCategory) {
              getWords = () => {
                return c.getWords(setPanel, false);
              };
            }
            getWords()
              .then(() => {
                newAllCategories[index].isLoading = false;
                addCategory(c);
                setAllCategories([...newAllCategories]);
              })
              .catch((e) => {
                if (e.displayError) {
                  gameContext.dispatch({
                    type: "DisplayError",
                    payload: {
                      msg: e.msg,
                      errorLevel: e.errorLevel,
                    },
                  });
                  console.warn(e.msg);
                }
                DataLayerPush("category_change", {
                  category_name: c.name,
                  category_checked: false,
                });
                newAllCategories[index].isLoading = false;
                setAllCategories([...newAllCategories]);
              });
          } else if (c instanceof Category) {
            addCategory(c);
          }
        } else {
          removeCategory(c);
        }
        setAllCategories([...newAllCategories]);
      };

      const onClickPanel = () => {
        const newAllCategories = allCategories;
        newAllCategories[index].isLoading = true;

        c.getWords(setPanel, true)
          .then(() => {
            newAllCategories[index].isLoading = false;
            addCategory(c);
            setAllCategories([...newAllCategories]);
          })
          .catch((e) => {
            if (c.word == null) {
              if (e.displayError) {
                gameContext.dispatch({
                  type: "DisplayError",
                  payload: {
                    msg: e.msg,
                    errorLevel: e.errorLevel,
                  },
                });
                console.warn(e.msg);
              }
              DataLayerPush("category_change", {
                category_name: c.name,
                category_checked: false,
              });
              newAllCategories[index].isLoading = false;
              removeCategory(c);
            }
            setAllCategories([...newAllCategories]);
          });
        setAllCategories([...newAllCategories]);
      };

      return (
        <div
          className={` ${!searchString.length && "sm:w-1/2"} w-full  min-h-9 `}
          data-filter={c.filter}
          key={c.name}
          translate={c.translateTitle}
        >
          <CategorySwitch
            isChecked={c.isOn}
            label={c.name}
            isLoading={c.isLoading}
            onChangeSwitch={onChangeSwitch}
            hasPanel={c instanceof PanelCategory}
            onClickPanel={onClickPanel}
          />
        </div>
      );
    });
  };

  const CategoriesFiltersJSX = (catJSX) => {
    let catFilterJSX = [];
    let filters = Object.values(FiltersEnum);
    for (let index = filters.length - 1; index >= 0; index--) {
      const filter = filters[index];
      catFilterJSX.push(
        <div
          key={FilterTitles[index] + "-filter"}
          className="text-DTC_Yellow font-bold text-xl my-3 w-full"
        >
          <h2
            translate={FilterTitles[index] === "Twitch Emotes" ? "no" : "yes"}
            className="mx-2"
          >
            {FilterTitles[index]}
          </h2>
          <div className="bg-DTC_Yellow w-56 h-1"></div>
        </div>
      );
      let hasCat = false;
      catJSX.forEach((cJSX) => {
        if (filter === cJSX.props["data-filter"]) {
          hasCat = true;
          catFilterJSX.push(cJSX);
        }
      });
      if (!hasCat) {
        catFilterJSX.pop();
      }
    }
    return catFilterJSX;
  };

  const SearchResults = () => {
    let cJSXs = CategoriesJSX();
    let searchStr = RegExpEscape(searchString);
    if (searchString.length) {
      let resultsJSX = [];
      resultsJSX.push(
        <div key="Results" className="text-gray-400 m-3 break-words">
          Search Results for <span className="italic">{searchString}</span>
        </div>
      );
      cJSXs.forEach((j) => {
        let key = j.key.toLowerCase();

        if (key.search(`${searchStr}`) != -1) {
          resultsJSX.push(j);
        }
      });
      return resultsJSX;
    } else {
      return CategoriesFiltersJSX(cJSXs);
    }
  };

  return React.useMemo(() => {
    return (
      <div>
        <div className="max-w-full flex h-11 bg-DTC_Yellow items-center">
          <FontAwesomeIcon
            icon={faSearch}
            className="h-full block align-middle mx-1 flex-none text-black"
            size="lg"
            style={{ width: "22px" }}
          />

          <input
            className="h-full p-1 pl-2 bg-DTC_Yellow w-full placeholder-black text-black font-bold text-lg"
            type="search"
            name="search"
            aria-label="Search"
            placeholder="Search"
            autoComplete="off"
            spellCheck={false}
            onChange={(e) => {
              setSearchString(e.target.value.toLowerCase());
            }}
          />
        </div>

        <div
          style={{ height: "39rem" }}
          className={`${
            !searchString.length && "flex"
          } flex-wrap border_bottom overflow-y-scroll pb-4`}
        >
          {SearchResults()}
        </div>
        <div className="mx-2">
          <span>{wordList.activeCats}</span>
          <span className="float-right p-1 border_no_top hover:bg-DTC_Yellow hover:text-black ">
            <Button OnClick={ClearAll}>Clear All</Button>
          </span>
        </div>

        {debugWordBank && (
          <div>
            <ListImageView wordList={wordList.words} />
            <button
              className="p-1 border"
              aria-label={"Wordlist"}
              onClick={() => {
                console.log(wordList);
                console.log(allCategories);
              }}
            >
              Console WordBank
            </button>
          </div>
        )}
      </div>
    );
  }, [wordList, searchString, allCategories, gameContext.state.gameState]);
};

WordBank.propTypes = {
  setPanel: PropTypes.func.isRequired,
};

export default WordBank;

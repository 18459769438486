import * as React from "react";
import { GameContext } from "../../game/store";
import { GameStateEnum } from "../../game/gameEnums.mjs";
import { DataLayerPush } from "../../utils/browser_utils";
import Tooltip from "../utils/Tooltip";

const NextBtn = () => {
  const gameContext = React.useContext(GameContext);
  const [disabled, setDisabled] = React.useState(true);

  React.useEffect(() => {
    if (gameContext.state.chatConnected && gameContext.state.wordListPrimed) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    gameContext.state.gameState,
    gameContext.state.wordListPrimed,
    gameContext.state.chatConnected,
  ]);

  const content = () => {
    return (
      <button
        disabled={disabled}
        aria-label="Next"
        className={`${disabled && "cursor-default"} ${
          disabled
            ? "opacity-10 btn-panel--disabled "
            : "btn-panel--enable hover:bg-DTC_LighterGreen"
        } bg-DTC_Green py-3 px-10 text-2xl font-bold border-2 border-white `}
        onClick={() => {
          gameContext.dispatch({ type: "StartRound" });
          if (
            gameContext.state.gameState != GameStateEnum.Won &&
            gameContext.state.gameState != GameStateEnum.Lost
          ) {
            DataLayerPush("round_skipped", {
              word: gameContext.state.wordInPlay.word,
              alt_words: gameContext.state.wordInPlay.altWords,
              category_name: gameContext.state.wordInPlay.categoryName.name,
              seconds_left: gameContext.state.wordInPlay.secondsLeft,
              seconds_set: gameContext.state.settings.timeDurationSecs,
            });
          }
        }}
      >
        Next
      </button>
    );
  };

  return (
    <>
      {!gameContext.state.wordListPrimed || !gameContext.state.chatConnected ? (
        <Tooltip
          allowClick={false}
          tip={
            gameContext.state.wordListPrimed
              ? gameContext.state.chatConnected
                ? ""
                : "Error chat is not connected. try refreshing the page"
              : "Select a category"
          }
        >
          {content()}
        </Tooltip>
      ) : (
        content()
      )}
    </>
  );
};

export default NextBtn;

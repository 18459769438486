import * as React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GameContext } from "../game/store";
import { ErrorLevelEnum } from "../game/gameEnums.mjs";

const ErrorDialog = () => {
  const gameContext = React.useContext(GameContext);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (gameContext.state.errorInfo.displayError) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [gameContext.state.errorInfo.displayError]);

  function closeModal() {
    gameContext.dispatch({ type: "CloseError" });
  }

  const GetTitle = () => {
    switch (gameContext.state.errorInfo.errorLevel) {
      case ErrorLevelEnum.ERROR:
        return "Oh no...big problem";
      case ErrorLevelEnum.WARN:
        return "Small..hiccup";
      case ErrorLevelEnum.INFO:
        return "Info";
      default:
        return "Info";
    }
  };

  const GetBg = () => {
    switch (gameContext.state.errorInfo.errorLevel) {
      case ErrorLevelEnum.ERROR:
        return "bg-red-900";
      case ErrorLevelEnum.WARN:
        return "bg-DTC_Bronze";
      case ErrorLevelEnum.INFO:
        return "bg-DTC_DarkBlue border";
      default:
        return "bg-DTC_DarkBlue border";
    }
  };

  return React.useMemo(() => {
    return (
      <>
        <Transition appear show={isOpen} as={React.Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen px-4 text-center">
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  className={`inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform ${GetBg()} shadow-xl rounded-sm`}
                >
                  <Dialog.Title
                    as="h2"
                    className="text-lg leading-6 text-zinc-50 font-bold"
                  >
                    {GetTitle()}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-base text-zinc-200">
                      {gameContext.state.errorInfo.msg}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 font-medium bg-glass rounded-sm"
                      onClick={closeModal}
                    >
                      Got it
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }, [isOpen]);
};

export default ErrorDialog;

import * as React from "react";
import { GameContext } from "../../game/store";
import {
  GameStateEnum,
  AuthTypeEnum,
  ErrorLevelEnum,
  ErrorTypeEnum,
} from "../../game/gameEnums.mjs";
import tmi from "tmi.js";
import { client_id, bypassChatRequirement } from "../../api";
import DOMPurify from "dompurify";

//https://github.com/tmijs/docs/blob/gh-pages/_posts/v1.4.2

const initialMsgState = {
  msg: "",
  display_name: "",
  colour: "",
  badges: {},
};
const useChat = () => {
  const gameContext = React.useContext(GameContext);

  const [msgState, setMsgState] = React.useState(initialMsgState);
  const messageEvent = React.useRef();
  const errorInfo = React.useRef();
  errorInfo.current = gameContext.state.errorInfo;

  //if chat is connected already just sub to message event
  React.useEffect(() => {
    if (gameContext.state.chatConnected && gameContext.state.chatInterface) {
      subToMessageEvent(
        gameContext.state.chatInterface,
        gameContext.state.user.authType
      );
    }

    return () => {
      if (
        gameContext.state.chatInterface &&
        messageEvent.current &&
        (gameContext.state.user.authType === AuthTypeEnum.Twitch ||
          gameContext.state.user.authType === AuthTypeEnum.TwitchChannelName)
      ) {
        gameContext.state.chatInterface.removeListener(
          "message",
          messageEvent.current
        );
      }
    };
  }, []);

  //After auth determine what chat interface to connect to
  React.useEffect(() => {
    if (gameContext.state.chatConnected) {
      return;
    }

    if (
      gameContext.state.gameState === GameStateEnum.Authed &&
      (gameContext.state.user.authType === AuthTypeEnum.Twitch ||
        gameContext.state.user.authType === AuthTypeEnum.TwitchChannelName) &&
      !bypassChatRequirement
    ) {
      ConnectTwtichChat();
    } else if (
      bypassChatRequirement &&
      gameContext.state.gameState === GameStateEnum.Authed
    ) {
      gameContext.dispatch({
        type: "ChatConnected",
        payload: { chatInterface: null },
      });
    }
  }, [gameContext.state.gameState]);

  const subToMessageEvent = (chatInterface, authType) => {
    if (
      authType === AuthTypeEnum.Twitch ||
      authType === AuthTypeEnum.TwitchChannelName
    ) {
      subToMessageEventTwitch(chatInterface);
    }
  };

  const subToMessageEventTwitch = (chatInterface) => {
    const messageListener = (channel, tags, message) => {
      if (tags["message-type"] === "chat") {
        const cleanMsg = DOMPurify.sanitize(message);
        setMsgState({
          msg: cleanMsg,
          display_name: tags["display-name"],
          colour: tags.color,
        });
      }
    };
    chatInterface.on("message", messageListener);
    messageEvent.current = messageListener;
  };

  const ConnectTwtichChat = () => {
    console.log("Ready for connection to chat");

    const tmi_client = new tmi.Client({
      options: {
        debug: false,
        skipUpdatingEmotesets: true,
        skipMembership: true,
      },
      client_id: client_id,
    });

    tmi_client.connect().catch((err) => {
      gameContext.dispatch({
        type: "DisplayError",
        payload: {
          msg: `Could not connect to Twitch servers. ${err}`,
          errorLevel: ErrorLevelEnum.ERROR,
        },
      });
    });
    tmi_client.on("connected", () => {
      console.log("Twitch Chat Servers Connected");

      tmi_client
        .join(gameContext.state.user.display_name)
        .then(() => {
          console.log(
            `Channel Joined to ${gameContext.state.user.display_name}`
          );

          subToMessageEventTwitch(tmi_client);

          if (
            errorInfo.current.displayError &&
            errorInfo.current.errorType == ErrorTypeEnum.Reconnect
          ) {
            gameContext.dispatch({ type: "CloseError" });
          }
          gameContext.dispatch({
            type: "ChatConnected",
            payload: { chatInterface: tmi_client },
          });
        })
        .catch((err) => {
          gameContext.dispatch({
            type: "DisplayError",
            payload: {
              msg: `Could not connect to ${gameContext.state.user.display_name} channel. ${err}`,
              errorLevel: ErrorLevelEnum.ERROR,
            },
          });
        });
    });

    tmi_client.on("disconnected", () => {
      gameContext.dispatch({
        type: "DisplayError",
        payload: {
          msg: "Lost connection to chat server. Waiting for reconnection....",
          errorLevel: ErrorLevelEnum.WARN,
          errorType: ErrorTypeEnum.Reconnect,
        },
      });
      gameContext.dispatch({
        type: "ChatDisconnected",
      });
    });
  };

  return { msgState: msgState, interface: gameContext.state.chatInterface };
};

export default useChat;
export { initialMsgState };

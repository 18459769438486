import * as React from "react";
import PropTypes from "prop-types";
import { GameContext } from "../../../game/store";
import { client_id } from "../../../api";
import Spinner from "../../utils/Spinner";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorLevelEnum } from "../../../game/gameEnums.mjs";
import {
  DataLayerPush,
  getTwitchChannelIDByDisplayName,
} from "../../../utils/browser_utils";

import CategorySwitch from "../mainmenu/CategorySwitch";
import {
  getBTTVEmotes,
  getFFZEmotes,
  getSubEmotes,
  get7TVEmotes,
} from "../../../game/categories/emotes_fetch";

const channelDataDefualt = {
  active: false,
  display_name: "???",
  id: "",
};

const OtherChannelEmotes = ({ setPanel, payload }) => {
  const gameContext = React.useContext(GameContext);
  const [channelData, setChannelData] = React.useState(channelDataDefualt);
  const [loading, setLoading] = React.useState(false);
  const [emotesStates, setEmotesStates] = React.useState([
    {
      name: "BTTV Emotes",
      isLoading: false,
      isChecked: false,
      words: [],
      fetch: getBTTVEmotes,
    },
    {
      name: "FFZ Emotes",
      isLoading: false,
      isChecked: false,
      words: [],
      fetch: getFFZEmotes,
    },
    {
      name: "Subscription Emotes",
      isLoading: false,
      isChecked: false,
      words: [],
      fetch: getSubEmotes,
    },
    {
      name: "7TV Emotes",
      isLoading: false,
      isChecked: false,
      words: [],
      fetch: get7TVEmotes,
    },
  ]);
  const [channelNameInput, setChannelNameInput] = React.useState("");
  const channelNameInputRef = React.useRef();
  channelNameInputRef.current = channelNameInput;

  const onExit = () => {
    const words = emotesStates.reduce(
      (acc, current) => acc.concat(current.words),
      []
    );
    let anyChecked = false;
    emotesStates.forEach((state) => {
      if (state.isChecked) {
        anyChecked = true;
      }
    });

    if (words.length > 0 && anyChecked) {
      payload.reslovePanel(words);
    } else {
      payload.rejectPanel();
    }

    setPanel("MainMenu");
  };

  const reset = () => {
    DataLayerPush("other_channel_update", {
      active: false,
      display_name: channelNameInputRef.current,
      error_msg: "",
    });
    setChannelNameInput("");
    setChannelData(channelDataDefualt);
    setEmotesStates(
      emotesStates.map((emoteSate) => {
        emoteSate.isChecked = false;
        emoteSate.isLoading = false;
        emoteSate.words = [];
        return emoteSate;
      })
    );
  };

  const onSubmmitChannel = (e) => {
    e.preventDefault();
    setLoading(true);
    getTwitchChannelIDByDisplayName(
      gameContext.state.user.access_token,
      client_id,
      channelNameInputRef.current
    )
      .then((data) => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
        setChannelNameInput("");
        setChannelData({
          active: true,
          display_name: data.display_name,
          id: data.id,
        });
        DataLayerPush("other_channel_update", {
          active: true,
          display_name: data.display_name,
          error_msg: "",
        });
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
        gameContext.dispatch({
          type: "DisplayError",
          payload: {
            msg: "Could not get channel. Make sure you entered the channel name correctly.",
            errorLevel: ErrorLevelEnum.INFO,
          },
        });
        DataLayerPush("other_channel_update", {
          active: false,
          display_name: channelNameInputRef.current,
          error_msg: "getting other channel failed",
        });
      });
    return false;
  };

  const enterChannelForm = () => {
    return (
      <div className="mt-10 text-center h-52 flex justify-center items-center w-full">
        {loading ? (
          <div className="mx-auto mt-10">
            <Spinner size="2.4rem" />
          </div>
        ) : (
          <div className="w-full">
            {channelData.active ? (
              <div>
                <span className="text-gray-300 block mt-4">
                  Set to:{" "}
                  <span translate="no" className="font-bold">
                    {channelData.display_name}
                  </span>
                </span>
                <button
                  className="bg-gray-700 py-2 px-2 block mt-5 mx-auto font-bold"
                  aria-label="Enter Channel Name"
                  onClick={reset}
                >
                  Revert
                </button>
              </div>
            ) : (
              <div>
                <form onSubmit={onSubmmitChannel}>
                  <input
                    className="h-12 w-3/5 p-1 pl-2 block mx-auto border-DTC_Yellow bg-DTC_Yellow text-black placeholder-gray-700 font-bold text-center text-lg mt-4"
                    type="text"
                    name="channel name"
                    aria-label="channel name"
                    placeholder="Twitch Channel Name"
                    spellCheck={false}
                    autoComplete="false"
                    onChange={(e) => {
                      setChannelNameInput(e.target.value);
                    }}
                  />
                  <button
                    type="submit"
                    className="bg-TwitchPurple py-2 px-2 block mt-5 mx-auto font-bold"
                    aria-label="Enter Channel Name"
                  >
                    Enter
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return React.useMemo(
    () => (
      <div className="p-3 relative">
        <Save
          onClick={onExit}
          channelIsloading={loading}
          emotesStates={emotesStates}
        />
        <h3 className="mt-4 font-bold text-3xl text-center">
          {"Another Channel's Emotes"}
        </h3>
        <h4 className="text-center">
          Use their BTTV, FFZ, 7TV, and Sub Emotes
        </h4>

        {enterChannelForm()}

        {channelData.active && (
          <div className="mt-8">
            {emotesStates.map((emoteState, index) => (
              <CategorySwitch
                key={`es-switch-${emoteState.name}`}
                label={`${channelData.display_name} ${emoteState.name}`}
                isChecked={emoteState.isChecked}
                isLoading={emoteState.isLoading}
                onChangeSwitch={(state) => {
                  const newEmoteStates = emotesStates;

                  if (state) {
                    if (emoteState.words.length === 0) {
                      newEmoteStates[index].isLoading = true;
                      emoteState
                        .fetch(gameContext.state.user, channelData.id)
                        .then(({ words }) => {
                          newEmoteStates[index].isLoading = false;
                          newEmoteStates[index].isChecked = true;
                          newEmoteStates[index].words = words;
                          setEmotesStates([...newEmoteStates]);
                        })
                        .catch((e) => {
                          newEmoteStates[index].isLoading = false;
                          if (e.displayError) {
                            gameContext.dispatch({
                              type: "DisplayError",
                              payload: {
                                msg: e.msg,
                                errorLevel: e.errorLevel,
                              },
                            });
                            DataLayerPush("category_change", {
                              category_name: `${channelData.display_name} ${emoteState.name}`,
                              category_checked: false,
                            });
                            console.warn(e.msg);
                          }
                          setEmotesStates([...newEmoteStates]);
                        });
                    } else {
                      newEmoteStates[index].isChecked = true;
                    }
                  } else {
                    newEmoteStates[index].isChecked = false;
                  }
                  setEmotesStates([...newEmoteStates]);
                }}
              ></CategorySwitch>
            ))}
          </div>
        )}
      </div>
    ),
    [
      channelData,
      loading,
      emotesStates,
      channelNameInput,
      channelNameInputRef,
      gameContext.state.user,
      payload,
    ]
  );
};

const Save = ({ onClick, emotesStates, channelIsloading }) => {
  const [disable, setDisable] = React.useState(false);

  React.useEffect(() => {
    let isLoading = false;
    emotesStates.forEach((state) => {
      if (state.isLoading) {
        isLoading = true;
      }
    });
    setDisable(isLoading);
  }, [emotesStates]);

  React.useEffect(() => {
    setDisable(channelIsloading);
  }, [channelIsloading]);

  return (
    <button
      className={`${
        disable && "cursor-default opacity-70"
      } border-2 px-4 py-2 text-xl border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black `}
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
    >
      <FontAwesomeIcon icon={faArrowLeft} />
      <span className="ml-2">Save</span>
    </button>
  );
};
Save.propTypes = {
  onClick: PropTypes.func.isRequired,
  emotesStates: PropTypes.array.isRequired,
  channelIsloading: PropTypes.bool.isRequired,
};

OtherChannelEmotes.propTypes = {
  setPanel: PropTypes.func.isRequired,
  payload: PropTypes.object,
};
export default OtherChannelEmotes;

import * as React from "react";
import Particles from "react-tsparticles";

import PaperFour from "../../images/LandingAnimation/Paper-4.png";
import PaperFive from "../../images/LandingAnimation/Paper-5.png";
import PaperSix from "../../images/LandingAnimation/Paper-6.png";
import PaperSeven from "../../images/LandingAnimation/Paper-7.png";
import PaperEight from "../../images/LandingAnimation/Papers-8.png";
import PaperNine from "../../images/LandingAnimation/Papers-9.png";
import PaperTen from "../../images/LandingAnimation/Papers-10.png";
import Paper11 from "../../images/LandingAnimation/Papers-11.png";
import Paper12 from "../../images/LandingAnimation/Papers-12.png";
import Paper13 from "../../images/LandingAnimation/Papers-13.png";
import Paper14 from "../../images/LandingAnimation/Papers-14.png";
import Paper15 from "../../images/LandingAnimation/Papers-15.png";
import Paper16 from "../../images/LandingAnimation/Papers-16.png";
import Paper17 from "../../images/LandingAnimation/Papers-17.png";
import Paper18 from "../../images/LandingAnimation/Papers-18.png";
import Paper19 from "../../images/LandingAnimation/Papers-19.png";
import Paper20 from "../../images/LandingAnimation/Papers-20.png";
import Paper21 from "../../images/LandingAnimation/Papers-21.png";
import Paper22 from "../../images/LandingAnimation/Papers-22.png";

const Landing = () => {
  return React.useMemo(() => {
    return (
      <Particles
        className="landingSpawn absolute h-full w-full z-10 opacity-30"
        id="tsparticlesland"
        options={{
          autoPlay: true,
          background: {
            image: "",
            repeat: "no-repeat",
            size: "cover",
            opacity: 0,
          },
          fullScreen: {
            enable: false,
            zIndex: -1,
          },
          detectRetina: true,
          duration: 0,
          fpsLimit: 30,
          manualParticles: [],
          motion: {
            disable: false,
            reduce: {
              factor: 4,
              value: true,
            },
          },
          particles: {
            bounce: {
              horizontal: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
              vertical: {
                random: {
                  enable: false,
                  minimumValue: 0.1,
                },
                value: 1,
              },
            },
            collisions: {
              bounce: {
                horizontal: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
                vertical: {
                  random: {
                    enable: false,
                    minimumValue: 0.1,
                  },
                  value: 1,
                },
              },
              enable: false,
              mode: "bounce",
              overlap: {
                enable: true,
                retries: 0,
              },
            },
            color: {
              value: "#ffffff",
              animation: {
                h: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  sync: true,
                },
                s: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  sync: true,
                },
                l: {
                  count: 0,
                  enable: false,
                  offset: 0,
                  speed: 1,
                  sync: true,
                },
              },
            },
            destroy: {
              mode: "none",
              split: {
                count: 1,
                factor: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 3,
                },
                rate: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: {
                    min: 4,
                    max: 9,
                  },
                },
                sizeOffset: true,
              },
            },
            gradient: [],
            groups: {},
            life: {
              count: 0,
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 0,
                sync: false,
              },
              duration: {
                random: {
                  enable: false,
                  minimumValue: 0.0001,
                },
                value: 0,
                sync: false,
              },
            },
            move: {
              angle: {
                offset: 0,
                value: 90,
              },
              attract: {
                distance: 200,
                enable: false,
                rotate: {
                  x: 600,
                  y: 1200,
                },
              },
              decay: 0,
              distance: {},
              direction: "top",
              drift: 0,
              enable: true,
              gravity: {
                acceleration: 9.81,
                enable: false,
                inverse: false,
                maxSpeed: 50,
              },
              path: {
                clamp: true,
                delay: {
                  random: {
                    enable: false,
                    minimumValue: 0,
                  },
                  value: 0,
                },
                enable: false,
                options: {},
              },
              outModes: {
                default: "out",
                bottom: "out",
                left: "out",
                right: "out",
                top: "out",
              },
              random: false,
              size: false,
              speed: 1,
              spin: {
                acceleration: 0,
                enable: false,
              },
              straight: false,
              trail: {
                enable: false,
                length: 10,
                fillColor: {
                  value: "#000000",
                },
              },
              vibrate: false,
              warp: false,
            },
            number: {
              density: {
                enable: true,
                area: 1800,
                factor: 1000,
              },
              limit: 10,
              value: 10,
            },
            // opacity: {
            //   animation: {
            //     count: 0,
            //     enable: true,
            //     speed: 0.4,
            //     sync: false,
            //     destroy: "none",
            //     startValue: "random",
            //     minimumValue: 0.4,
            //   },
            // },
            orbit: {
              animation: {
                count: 0,
                enable: false,
                speed: 1,
                sync: false,
              },
              enable: false,
              opacity: 1,
              rotation: {
                random: {
                  enable: false,
                  minimumValue: 0,
                },
                value: 45,
              },
              width: 1,
            },
            reduceDuplicates: false,
            repulse: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              enabled: false,
              distance: 1,
              duration: 1,
              factor: 1,
              speed: 1,
            },
            roll: {
              darken: {
                enable: false,
                value: 0,
              },
              enable: false,
              enlighten: {
                enable: false,
                value: 0,
              },
              mode: "vertical",
              speed: 25,
            },
            rotate: {
              random: {
                enable: true,
                minimumValue: 0,
              },
              value: 0,
              animation: {
                enable: true,
                speed: 5,
                sync: false,
              },
              direction: "random",
              path: false,
            },
            shape: {
              options: {
                character: {
                  fill: false,
                  font: "Verdana",
                  style: "",
                  value: "*",
                  weight: "400",
                },
                char: {
                  fill: false,
                  font: "Verdana",
                  style: "",
                  value: "*",
                  weight: "400",
                },
                polygon: {
                  sides: 5,
                },
                star: {
                  sides: 5,
                },
                images: [
                  {
                    src: PaperFour,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: PaperFive,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: PaperSix,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: PaperSeven,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: PaperEight,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: PaperNine,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: PaperTen,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper11,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper12,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper13,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper14,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper15,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper16,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper17,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper18,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper19,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper20,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper21,
                    width: 65,
                    height: 65,
                  },
                  {
                    src: Paper22,
                    width: 65,
                    height: 65,
                  },
                ],
              },
              type: "image",
            },
            size: {
              random: {
                enable: true,
                minimumValue: 50,
              },
              value: 75,
              animation: {
                count: 0,
                enable: false,
                speed: 40,
                sync: false,
                destroy: "none",
                startValue: "random",
                minimumValue: 0.1,
              },
            },
            stroke: {
              width: 0,
              color: {
                value: "#000000",
                animation: {
                  h: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    sync: true,
                  },
                  s: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    sync: true,
                  },
                  l: {
                    count: 0,
                    enable: false,
                    offset: 0,
                    speed: 1,
                    sync: true,
                  },
                },
              },
            },
            tilt: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              animation: {
                enable: false,
                speed: 0,
                sync: false,
              },
              direction: "clockwise",
              enable: false,
            },
            twinkle: {
              lines: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
              particles: {
                enable: false,
                frequency: 0.05,
                opacity: 1,
              },
            },
            wobble: {
              distance: 5,
              enable: false,
              speed: 50,
            },
            zIndex: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
              opacityRate: 1,
              sizeRate: 1,
              velocityRate: 1,
            },
          },
          pauseOnBlur: true,
          pauseOnOutsideViewport: true,
          themes: [],
          zLayers: 100,
        }}
      />
    );
  }, []);
};

export default Landing;

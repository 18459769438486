import * as React from "react";
import { GameContext } from "../game/store";
import { DataLayerPush } from "../utils/browser_utils";

const NextBtn = () => {
  const gameContext = React.useContext(GameContext);
  const [disabled, setDisabled] = React.useState(true);

  React.useEffect(() => {
    if (gameContext.state.chatConnected && gameContext.state.wordListPrimed) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    gameContext.state.gameState,
    gameContext.state.wordListPrimed,
    gameContext.state.chatConnected,
  ]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className=" block text-center font-bold text-4xl lg:text-6xl mb-8">
        {gameContext.state.wordListPrimed ? (
          gameContext.state.chatConnected ? (
            <span>Press Begin!</span>
          ) : (
            <span>Problem, chat is not connected</span>
          )
        ) : (
          <span>Select a Category</span>
        )}
      </div>

      <div className="relative mx-auto ">
        <button
          disabled={disabled}
          aria-label="Begin"
          className={`${disabled && "cursor-default"} ${
            disabled
              ? "opacity-20 btn-panel--disabled "
              : "btn-panel--enable hover:bg-DTC_LighterGreen"
          } bg-DTC_Green py-3 px-10 text-2xl font-bold border-2 border-white`}
          onClick={() => {
            gameContext.dispatch({ type: "StartRound" });
            gameContext.dispatch({
              type: "UpdateShowSidePanel",
              payload: { showSidePanel: false },
            });
            DataLayerPush("game_started");
          }}
        >
          <span>Begin</span>
        </button>
      </div>
    </div>
  );
};

export default NextBtn;

import * as React from "react";
import CheckBox from "../CheckBox";
import PropTypes from "prop-types";
import ImageView from "../ImageView";

//Used for debugging images
const ListImageView = ({ wordList }) => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <CheckBox
        label={"Display WordList"}
        defualtValue={false}
        onChange={(value) => {
          setShow(value);
        }}
      />
      {show && (
        <div>
          {wordList.map((w, index) => {
            return (
              <div key={`${w.word}-${index}`}>
                <h1 className="text-lg font-bold">{w.word}</h1>
                <ImageView showWord={show} height={80} wordObj={w} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

ListImageView.propTypes = {
  wordList: PropTypes.array.isRequired,
};

export default ListImageView;

import * as React from "react";
import { GameContext } from "../../../game/store";
import { GameStateEnum, ErrorLevelEnum } from "../../../game/gameEnums.mjs";
import NewWindow from "../../utils/NewWindow";
import Button from "../../utils/Button";
import ImageView from "../../utils/ImageView";
//https://github.com/rmariuzzo/react-new-window

const WordWindow = () => {
  const gameContext = React.useContext(GameContext);
  const [showWindow, setShowWindow] = React.useState(false);
  const [displayWord, setDisplayWord] = React.useState(false);
  const windowRef = React.useRef(null);

  React.useEffect(() => {
    //reopen a blocked popup
    window.reOpenBlocked = () => {
      OpenWordWindow();
    };
  }, []);

  const OpenWordWindow = () => {
    if (!showWindow) {
      setShowWindow(true);
    } else {
      windowRef.current.window.focus();
    }
  };

  React.useEffect(() => {
    if (gameContext.state.gameState === GameStateEnum.RoundStart) {
      OpenWordWindow();
    }
  }, [gameContext.state.gameState]);

  if (typeof window !== "undefined") {
    //unload the window when main site closes
    window.onbeforeunload = function () {
      if (showWindow) {
        windowRef.current.window.close();
      }
    };
    //check if the main site is in a popup. This is caused when popups are blocked and later allowed
    if (window.opener && window.opener !== window && !window.menubar.visible) {
      if (window.opener) {
        window.opener.reOpenBlocked();
      }
      window.close();
    }
  }

  return React.useMemo(() => {
    return (
      <>
        {showWindow && (
          <NewWindow
            ref={windowRef}
            center="screen"
            title={"Da Stream Charades - Word Window"}
            features={{
              width: "760px",
              height: "450px",
              menubar: "no",
              location: "no",
              directories: "no",
              status: "no",
              resizable: "no",
              popup: "yes",
            }}
            onBlock={() => {
              gameContext.dispatch({
                type: "DisplayError",
                payload: {
                  msg: "Could not open Word Window. Make sure to allow popups for this site.",
                  errorLevel: ErrorLevelEnum.ERROR,
                },
              });
              setShowWindow(false);
            }}
            onUnload={() => {
              setShowWindow(false);
              setDisplayWord(false);
            }}
            onOpen={() => {
              setTimeout(() => {
                console.log(windowRef.current);
                windowRef.current.window.document.body.style.backgroundColor =
                  "#001547";
              }, 10);
            }}
          >
            <div lang="en" className="px-4 py-8">
              <div className="mx-auto my-0 w-full text-center mb-7">
                {gameContext.state.gameState >= GameStateEnum.RoundStart ? (
                  <div>
                    <Button
                      OnClick={() => {
                        setDisplayWord(!displayWord);
                      }}
                      label="Display Word"
                    >
                      <span className="border-2 border-DTC_Yellow hover:bg-DTC_Yellow hover:text-black py-2 px-7">
                        {displayWord ? "Hide" : "Show"}
                      </span>
                    </Button>
                    <span className="block my-5 text-lg opacity-70">
                      Your Word Is...
                    </span>
                  </div>
                ) : (
                  <div style={{ height: "72px" }}> </div>
                )}
              </div>
              {displayWord ? (
                <div className="flex w-full justify-between gap-x-7">
                  <div>
                    <div>
                      <h2 className="text-xl text-DTC_Yellow">
                        From Category:
                      </h2>
                      <span
                        translate={
                          gameContext.state.wordInPlay.categoryName.translate
                        }
                      >
                        {gameContext.state.wordInPlay.categoryName.name}
                      </span>
                    </div>
                    {gameContext.state.wordInPlay.byUser && (
                      <div className="mt-2">
                        <h2 className="text-xl text-DTC_Yellow">By User:</h2>
                        <span
                          style={{
                            color: gameContext.state.wordInPlay.userColour,
                          }}
                          className="block font-bold"
                        >
                          {gameContext.state.wordInPlay.byUser}
                        </span>
                      </div>
                    )}

                    {gameContext.state.wordInPlay.altWords.length ? (
                      <div className="mt-2">
                        <h2 className="text-xl text-DTC_Yellow">Also Valid:</h2>
                        {gameContext.state.wordInPlay.altWords.map(
                          (altWordObj) => {
                            return (
                              <span
                                translate="no"
                                className="block"
                                key={altWordObj.word}
                              >
                                <span>-{altWordObj.word}</span>
                                {altWordObj.translated && (
                                  <sub className="ml-1 text-xs opacity-40">{`(${
                                    gameContext.state.supporedSecondaryLangs.find(
                                      (langObj) =>
                                        altWordObj.lang === langObj.lang
                                    ).locals.translated
                                  })`}</sub>
                                )}
                              </span>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center mt-3">
                    <span translate="no" className="font-bold text-4xl">
                      {gameContext.state.wordInPlay.word}
                    </span>
                    <hr className="border-1 border-white border-opacity-40 w-28 mx-auto opacity-80 mt-2 mb-3" />
                    <ImageView showWord={displayWord} height={150} />
                  </div>
                  <div />
                </div>
              ) : (
                <div
                  style={{ height: "223px" }}
                  className="font-bold text-9xl text-center w-full"
                >
                  ?
                </div>
              )}
            </div>
            <p className="italic opacity-70 absolute bottom-1 left-2">
              Don{"'"}t Show This Window On Stream
            </p>
          </NewWindow>
        )}

        <Button
          OnClick={() => {
            OpenWordWindow();
          }}
          label="Open Word Window"
        >
          <div className="border-2 border-DTC_Yellow p-3 font-semibold hover:bg-DTC_Yellow hover:text-black">
            Word Window
          </div>
        </Button>
      </>
    );
  }, [showWindow, displayWord, gameContext.state.wordInPlay.word]);
};

export default WordWindow;

import * as React from "react";
import categories_json from "../../../game/categories/categories.json";
import CheckBox from "../CheckBox";
import PropTypes from "prop-types";
import { objToCategoryMarkdown } from "../../../game/categories/categoryParser.mjs";

const StaticCategoryEditor = () => {
  const [categories, setCategoires] = React.useState([]);
  const [activeCategory, setActiveCategory] = React.useState(null);

  const activeCatRef = React.useRef();
  activeCatRef.current = activeCategory;

  React.useEffect(() => {
    init();

    //start auto save
    const saveInterval = setInterval(() => {
      if (activeCatRef.current) {
        save(activeCatRef.current);
      }
    }, 15000);

    return () => {
      clearInterval(saveInterval);
    };
  }, []);

  const init = () => {
    setCategoires(
      categories_json.map((category) => {
        return { ...category, id: new Date().getTime() + category.posiiton };
      })
    );
  };

  const save = (category) => {
    localStorage.setItem(
      `${category.name}-cat-editor-save`,
      JSON.stringify(category)
    );
    console.log("Saved");
  };

  const load = (categoryObj) => {
    const loadedCat = localStorage.getItem(
      `${categoryObj.name}-cat-editor-save`
    );
    if (loadedCat) {
      const newActiveCat = JSON.parse(loadedCat);
      setActiveCategory({
        ...newActiveCat,
        id: new Date().getTime() + categoryObj.posiiton,
      });
      init();
      console.log("Loaded");
    } else {
      setActiveCategory({ ...structuredClone(categoryObj) });
    }
  };

  const convertToMarkdown = () => {
    const cat = activeCategory;
    delete cat.id;
    navigator.clipboard.writeText(objToCategoryMarkdown(cat));
    console.log("Copied to Clipboard");
  };

  return (
    <div className="w-full">
      <div className="flex flex-col justify-center items-center  gap-5">
        {categories.map((categoryObj) => (
          <button
            className="bg-gray-600 p-2 "
            key={`debug-cat-${categoryObj.id}`}
            onClick={() => {
              load(categoryObj);
            }}
          >
            {categoryObj.name}
          </button>
        ))}
      </div>

      <div className="m-2 flex flex-col justify-center items-center relative pt-8">
        {activeCategory && (
          <>
            <button
              className="left-1 top-0 absolute text-sm bg-blue-700 z-10 px-2"
              onClick={convertToMarkdown}
            >
              Copy Markdown
            </button>

            {activeCategory.words.map((wordObj, index) => (
              <WordEditor
                key={`word-${index}-${activeCategory.id}`}
                wordObj={wordObj}
                index={index}
                defualtWordObj={structuredClone(
                  categories_json[activeCategory.posiiton - 1].words[index]
                )}
                onChange={(newWordObj) => {
                  const newWordList = activeCategory.words;
                  newWordList[index] = newWordObj;
                  setActiveCategory({
                    ...activeCategory,
                    words: [...newWordList],
                  });
                }}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const WordEditor = ({ wordObj, defualtWordObj, onChange, index }) => {
  const [wordObjState, setWordObjState] = React.useState(wordObj);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setWordObjState(initState(wordObjState));
    setReady(true);
  }, []);

  React.useEffect(() => {
    onChange(wordObjState);
  }, [wordObjState]);

  const initState = (rawWordObj) => {
    //give alt word an id, used for react keys
    if (rawWordObj.altWords) {
      let newAltWords = rawWordObj.altWords;
      const currentTime = new Date().getTime();
      newAltWords = newAltWords.map((altWordObj, index) => {
        return { ...altWordObj, id: index + currentTime };
      });
      return { ...rawWordObj, altWords: newAltWords };
    }

    return rawWordObj;
  };

  const onReset = () => {
    setWordObjState(initState(structuredClone(defualtWordObj)));
  };

  return (
    ready && (
      <div className="bg-gray-500 p-5 w-full m-3 relative h-135">
        <h2 className="text-2xl font-bold inline">Word: </h2>
        <button className=" absolute right-2 bg-red-500 px-2" onClick={onReset}>
          Reset To JSON
        </button>
        <input
          className="text-black text-lg p-2 font-bold"
          value={wordObjState.word}
          onChange={({ target: { value } }) => {
            setWordObjState({ ...wordObjState, word: value });
          }}
        />
        <span className="ml-2 text-xl">{`Index: ${index}`} </span>
        <div className="border-2 p-2 my-2 h-5/6  overflow-scroll">
          <h3 className="text-xl">Alt Words</h3>
          {wordObjState.altWords &&
            wordObjState.altWords.map((altWordObj, index) => {
              return (
                <AltWordEditor
                  key={`altWord-${altWordObj.id}-${wordObjState.word}`}
                  altWordObj={altWordObj}
                  onChange={(newAltWordObj) => {
                    const newAltWordList = wordObjState.altWords;
                    newAltWordList[index] = newAltWordObj;
                    setWordObjState({
                      ...wordObjState,
                      altWords: [...newAltWordList],
                    });
                  }}
                  onRemove={() => {
                    const newAltWordList = wordObjState.altWords;
                    newAltWordList.splice(index, 1);
                    setWordObjState({
                      ...wordObjState,
                      altWords: [...newAltWordList],
                    });
                  }}
                />
              );
            })}

          <button
            className="bg-black p-2"
            onClick={() => {
              let newAltWords = wordObjState.altWords;
              if (!newAltWords) {
                newAltWords = [];
              }
              newAltWords = [{ word: "", id: newAltWords.length }].concat(
                newAltWords
              );
              setWordObjState({ ...wordObjState, altWords: [...newAltWords] });
            }}
          >
            Add
          </button>
        </div>
      </div>
    )
  );
};

WordEditor.propTypes = {
  wordObj: PropTypes.object,
  defualtWordObj: PropTypes.object,
  onChange: PropTypes.func,
  index: PropTypes.number,
};

const AltWordEditor = ({ altWordObj, onChange, onRemove }) => {
  const [altWordObjState, setAltWordObjState] = React.useState(altWordObj);
  const [doRemove, setDoRemove] = React.useState(false);

  React.useEffect(() => {
    onChange(altWordObjState);
  }, [altWordObjState]);

  React.useEffect(() => {
    setAltWordObjState(altWordObj);
  }, [altWordObj]);

  React.useEffect(() => {
    if (doRemove) {
      onRemove();
    }
  }, [doRemove]);

  return (
    <div className="bg-gray-500 pb-5 pt-1 w-full flex">
      {typeof altWordObjState.lang !== "undefined" && (
        <div>
          <input
            className="text-black p-1 font-bold inline w-6"
            maxLength="2"
            value={altWordObjState.lang}
            onChange={({ target: { value } }) => {
              setAltWordObjState({ ...altWordObjState, lang: value });
            }}
          />
          =
        </div>
      )}

      <input
        className="text-black p-2 font-bold inline"
        value={altWordObjState.word}
        onChange={({ target: { value } }) => {
          setAltWordObjState({ ...altWordObjState, word: value });
        }}
      />

      <button
        onClick={() => {
          setDoRemove(true);
        }}
        className="bg-red-700 px-2"
      >
        Remove
      </button>

      {typeof altWordObjState.lang !== "undefined" ? (
        <>
          <button
            className="mx-2 bg-blue-800 px-2"
            onClick={() => {
              const newAltWordState = altWordObjState;
              delete newAltWordState.lang;
              setAltWordObjState({ ...newAltWordState });
            }}
          >
            Remove Lang
          </button>

          <CheckBox
            label="Translated"
            defualtValue={altWordObjState.translated ? true : false}
            onChange={(value) => {
              const newAltWordObjState = altWordObjState;
              if (!value) {
                delete altWordObjState.translated;
              } else {
                altWordObjState.translated = true;
              }
              setAltWordObjState({ ...newAltWordObjState });
            }}
          />
        </>
      ) : (
        <button
          className="mx-2 bg-blue-600 px-2"
          onClick={() => {
            setAltWordObjState({ ...altWordObjState, lang: "" });
          }}
        >
          Add Lang
        </button>
      )}
    </div>
  );
};

AltWordEditor.propTypes = {
  altWordObj: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default StaticCategoryEditor;

import { createContext } from "react";
import {
  GameStateEnum,
  AuthTypeEnum,
  ErrorLevelEnum,
  ErrorTypeEnum,
} from "./gameEnums.mjs";
import supporedSecondaryLangs from "./supporedSecondaryLangs.js";

const GameContext = createContext();

//https://lingohub.com/academy/best-practices/iso-639-1-list

const initialState = {
  user: {
    id: "",
    access_token: "",
    login: "",
    display_name: "",
    viewer_count: 0,
    authType: AuthTypeEnum.None,
  },
  wordInPlay: {
    word: "",
    imageURL: "",
    height: 0,
    width: 0,
    wordCheck: [],
    categoryName: {
      name: "",
      translate: "",
    },
    altWords: [],
    secondsLeft: 0,
  },
  settings: {
    maxTop: 15, // max top scores for streamer score
    startDelay: 5,
    timeDurationSecs: 35,
    volume: 0.8,
    canDoLocalStorage: true,
    maxSelections: 50,
    maxCharacterCount: 45,
    nonStrictCheck: true,
    pauseOnEachRound: false,
    maxStreak: 5,
    timerWarnThreshold: 10,
    muteStreaks: false,
    secondaryLang: { lang: "none", label: "None" },
  },
  errorInfo: {
    displayError: false,
    errorLevel: ErrorLevelEnum.INFO,
    msg: "",
    errorType: ErrorTypeEnum.Undefined,
  },
  scoreList: [],
  rounds: 0,
  roundsWon: 0,
  wordPicked: false,
  supporedSecondaryLangs: supporedSecondaryLangs,
  streak: 0,
  playWinAnimation: null,
  showSidePanel: true,
  chatConnected: false,
  chatInterface: null,
  wordListPrimed: true,
  gameState: GameStateEnum.SignIn,
};

export { initialState, GameContext };

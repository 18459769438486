import * as React from "react";
import { GameContext } from "../../game/store";
import { GameStateEnum } from "../../game/gameEnums";
import useChat, { initialMsgState } from "../utils/useChat";
import Timer from "./Timer";
import StartDelayTimer from "./StartDelayTimer";
import { calcScore, numberWithCommas, updateScores } from "./ScoreBoardUtils";

const initalWinnerScore = {
  score: 0,
  totalScore: 0,
  scoreDisplay: 0,
};

const Chat = () => {
  const gameContext = React.useContext(GameContext);
  const { msgState } = useChat();
  const [msgDisplay, setMsgDisplay] = React.useState(initialMsgState);
  const [hasWon, setHasWon] = React.useState();
  const [hasLost, setHasLost] = React.useState();

  const [winnerScore, setWinnerScore] = React.useState();
  const winnerScoreRef = React.useRef();
  winnerScoreRef.current = winnerScore;

  React.useEffect(() => {
    if (gameContext.state.gameState === GameStateEnum.RoundStart) {
      setMsgDisplay(initialMsgState);
      setWinnerScore(initalWinnerScore);
    }

    setHasWon(gameContext.state.gameState === GameStateEnum.Won);
    setHasLost(gameContext.state.gameState === GameStateEnum.Lost);
  }, [gameContext.state.gameState]);

  React.useEffect(() => {
    //Message Word Check
    if (gameContext.state.gameState === GameStateEnum.Playing) {
      //for chat words, if preventSameUserGuess option is on; skip this user's guess
      if (gameContext.state.wordInPlay.preventSameUserGuess) {
        if (
          gameContext.state.wordInPlay.byUser.toLowerCase() ===
          msgState.display_name.toLowerCase()
        ) {
          return;
        }
      }
      //Check all words that are vaild
      for (
        let index = 0;
        index < gameContext.state.wordInPlay.wordCheck.length;
        index++
      ) {
        const wordReg = gameContext.state.wordInPlay.wordCheck[index];
        if (msgState.msg.toLowerCase().trim().search(wordReg) != -1) {
          won();
        }
      }
      setMsgDisplay(msgState);
    }
  }, [msgState]);

  const won = () => {
    //update score list
    updateUserScore(msgState);

    //animate number going up
    const interval = setInterval(() => {
      if (winnerScoreRef.current.totalScore === 0) {
        return;
      }
      if (
        winnerScoreRef.current.scoreDisplay >= winnerScoreRef.current.totalScore
      ) {
        clearInterval(interval);
        return;
      }

      setWinnerScore({
        ...winnerScoreRef.current,
        scoreDisplay: winnerScoreRef.current.scoreDisplay + 1,
      });
    }, 25);

    //send event
    gameContext.dispatch({ type: "Won" });
  };

  const updateUserScore = (msgState) => {
    const timeLeft = gameContext.state.wordInPlay.secondsLeft;
    const time = gameContext.state.settings.timeDurationSecs;

    const scoreObj = {
      username: msgState.display_name,
      score: calcScore(timeLeft, time),
      colour: msgState.colour,
    };
    const { totalScore, scoreList } = updateScores(
      gameContext.state.scoreList,
      scoreObj
    );

    setWinnerScore({
      score: scoreObj.score,
      totalScore: totalScore,
      scoreDisplay: totalScore - scoreObj.score,
    });

    gameContext.dispatch({
      type: "UpdateScoreList",
      payload: {
        scoreList: scoreList,
      },
    });
  };

  return (
    <div
      className={`transition-all relative duration-300 text-center ${
        !hasWon && !hasLost && "border-gray-200 border-2 border-opacity-50"
      } ${hasLost && "border-red-600 border-2"}
         h-100 relative bg-DTC_DarkLightBlue`}
    >
      <div
        className={`border-winChat w-full h-100 ${
          hasWon && "border-winChat--active"
        }`}
      ></div>
      <div className="break-words flex justify-between flex-col h-full w-full p-1 mx-auto z-20 absolute">
        <div className=" h-12">
          {gameContext.state.gameState === GameStateEnum.RoundStart ||
          gameContext.state.gameState === GameStateEnum.RoundStarting ? (
            <StartDelayTimer />
          ) : (
            <Timer />
          )}
        </div>

        {hasWon && (
          <span
            translate="no"
            className="absolute top-32 w-full text-4xl font-bold text-yellow-600 flying-text"
          >
            +{winnerScore.score}
          </span>
        )}

        <div className="p-4 mb-6 text-xl overflow-y-scroll">
          {(gameContext.state.gameState == GameStateEnum.Playing || hasWon) &&
          msgDisplay.msg != "" ? (
            <div
              key={msgDisplay.msg}
              translate="no"
              className={`${
                hasWon ? "text-2xl text-chat-pop-large" : "text-chat-pop-small"
              }`}
            >
              <div>
                <span
                  translate="no"
                  className="font-bold"
                  style={{ color: msgDisplay.colour }}
                >
                  {msgDisplay.display_name}
                  <span className="text-white font-normal">{": "}</span>
                </span>
                <span translate="no">{msgDisplay.msg}</span>
              </div>
              {hasWon && (
                <span
                  translate="no"
                  className="relative font-bold w-36 text-yellow-600 spawn"
                >
                  Score: {numberWithCommas(winnerScore.scoreDisplay)}
                </span>
              )}
            </div>
          ) : (
            <span
              className={`${
                hasLost && "text-red-600 transition-color duration-300"
              } font-bold`}
            >
              {"..."}
            </span>
          )}
        </div>
        <div className="w-0 h-0"></div>
      </div>
    </div>
  );
};

export default Chat;

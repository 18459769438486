import * as React from "react";
import Logo from "../../images/DTC2_Logo.inline.svg";
import packageInfo from "../../../package.json";

const Header = () => {
  return React.useMemo(() => {
    return (
      <div className="border-DTC_Yellow border-r p-2 pr-8 bg-DTC_DarkBlue flex items-center ">
        <h1 translate="no" className="font-bold inline text-3xl sm:text-4xl">
          Da Stream Charades
        </h1>
        <div className="ml-3">
          <Logo className="w-10 h-10" />
          <p translate="no" className="opacity-70">
            v{packageInfo.version}
          </p>
        </div>
      </div>
    );
  }, []);
};

export default Header;

// const scoreObj = {
//     score: 12,
//     colour: "",
//     username: ""
// }

//can be better, by using a linked list. currently a O(4*n)
const updateScores = (scoreList = [], scoreObj) => {
  let existingScore = structuredClone(scoreObj);

  for (let index = 0; index < scoreList.length; index++) {
    const sortedScoreObj = scoreList[index];

    //found existing
    if (existingScore.username === sortedScoreObj.username) {
      existingScore.score += scoreList[index].score;
      scoreList.splice(index, 1);
      break;
    }
  }

  for (let index = 0; index < scoreList.length; index++) {
    const sortedScoreObj = scoreList[index];
    //insert
    if (existingScore.score > sortedScoreObj.score) {
      scoreList.splice(index, 0, existingScore);
      return { scoreList: scoreList, totalScore: existingScore.score };
    }
  }

  //if list is empty
  scoreList.push(existingScore);
  return { scoreList: scoreList, totalScore: existingScore.score };
};

const scoreTable = {
  base: 60,
  timeBonus: 30,
  upperLimitTime: 1, // upper limit on score when a user guessed too fast (Spam Mitigation)
  limitTimeBonus: 10, // timeBonus when a user guess within upperLimitTime

  roundWon: 50, // for round won how much score is rewarded (streamer only)
};

const calcScore = (timeLeft, totalTime) => {
  const timePassed = totalTime - timeLeft;
  let score = 0;
  //base
  score += scoreTable.base;
  //time
  const timeBonus =
    timePassed <= scoreTable.upperLimitTime
      ? scoreTable.limitTimeBonus
      : scoreTable.timeBonus;
  let timeScore = timeBonus - timePassed * 2;
  score += timeScore > 0 ? timeScore : 0;

  return score;
};

const calcStreamerScore = (scoreList, top, roundsWon) => {
  let score = 0;

  scoreList
    .filter((_, index) => index < top)
    .map((scoreObj) => {
      score += scoreObj.score;
    });

  score += roundsWon * scoreTable.roundWon;

  return score;
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export { updateScores, calcScore, calcStreamerScore, numberWithCommas };

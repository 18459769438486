import { DynamicCategory } from "./dynamic_category";
import { FiltersEnum, AuthTypeEnum } from "../gameEnums.mjs";

/**
 * Must have a react component under "panelcategories" folder
 * with props of { setPanel, payload }
 * and the appropriate code in SidePanel.js
 *
 * setPanel: will be used for switching back to "MainMenu" when given the name
 *
 * payload: savePanel(words: Array), reslove(), reject({ displayError: bool, msg: String }), userData
 *
 * when switching back you must call either a payload.reslovePanel(words: Array) or  payload.rejectPanel()
 *
 * See CustomWords.js for an exmaple
 */
export class PanelCategory extends DynamicCategory {
  /**
   *
   * @param name Name of category to display
   * @param panelName Name of panel component used to switch panel
   * @param filter To be grouped with other categories. Must be type FiltersEnum
   * @param authTypes Array of supported auth types. [] means all
   */
  constructor(name, panelName, filter, authTypes) {
    super(name, "yes", null, filter, authTypes);
    this.panelName = panelName;
  }

  /**
   *
   * @param setPanel func from SidePanel component
   * @param forceFetch If true will always run its fetch func, else return this.words if not null
   * @returns  Promise: reslove(), reject({ displayError: bool, msg: String, errorLevel: ErrorLevelEnum })
   */
  getWords(setPanel, forceFetch = false) {
    return new Promise((reslove, reject) => {
      if (this.words != null && !forceFetch) {
        reslove();
      } else {
        this.showPanel(setPanel, reslove, reject);
      }
    });
  }

  showPanel(setPanel, reslove, reject) {
    const reslovePanel = (words) => {
      this.words = words;
      reslove();
    };

    const rejectPanel = () => {
      this.words = null;
      reject({ displayError: false });
    };

    let payload = {
      reslovePanel: reslovePanel.bind(this),
      rejectPanel: rejectPanel.bind(this),
      words: this.words,
      userData: this.userData,
    };
    setPanel(this.panelName, payload);
  }
}

export let panel_categories = [];

panel_categories.push(
  new PanelCategory("Custom Words", "CustomWords", FiltersEnum.CustomWords, [])
);

panel_categories.push(
  new PanelCategory(
    "Use Another Channel's Emotes",
    "OtherChannelEmotes",
    FiltersEnum.YourChannel,
    [AuthTypeEnum.Twitch]
  )
);

panel_categories.push(
  new PanelCategory("Chat's Words", "ChatWords", FiltersEnum.CustomWords, [
    AuthTypeEnum.Twitch,
    AuthTypeEnum.TwitchChannelName,
  ])
);

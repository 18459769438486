import React from "react";
import ChatPanel from "./chatpanel/ChatPanel";
import TopScoresPanel from "./scorePanel/TopScoresPanel";
import useShowPanel from "./utils/useShowPanel";
const PlayPanel = () => {
  const showPanel = useShowPanel();
  return (
    <div
      className={`w-full h-full flex flex-col xl:flex-row justify-center  gap-x-1 transition-opacity duration-500 ${
        showPanel ? "opacity-40" : "opacity-100"
      }`}
    >
      <div className="self-center pb-5 w-full xl:w-2/12 flex justify-end h-full xl:h-52 order-3 xl:order-1">
        <TopScoresPanel />
      </div>
      <div className=" w-full xl:w-230 order-2">
        <ChatPanel />
      </div>
      <div className="hidden xl:block w-2/12 order-3"></div>
    </div>
  );
};

export default PlayPanel;

const GameStateEnum = Object.freeze({
  SignIn: 0,
  Authed: 1,
  ChatConnected: 2,
  RoundStart: 3,
  RoundStarting: 4,
  Playing: 5,
  Paused: 6,
  Lost: 7,
  Won: 8,
});
const ErrorLevelEnum = Object.freeze({ INFO: 0, WARN: 1, ERROR: 2 });
const AuthTypeEnum = Object.freeze({
  None: 0,
  Twitch: 1,
  TwitchChannelName: 2,
  Youtube: 3,
});
const ErrorTypeEnum = Object.freeze({ Undefined: 0, Reconnect: 1 });
const FiltersEnum = Object.freeze({
  All: 0,
  Anime: 1,
  FilmAndTV: 2,
  FamousPeople: 3,
  VideoGames: 4,
  TwitchEmotes: 5,
  YourChannel: 6,
  CustomWords: 7,
});
const FilterTitles = [
  "Others",
  "Anime",
  "Film and TV",
  "Famous People",
  "Video Games",
  "Twitch Emotes",
  "Channel Emotes",
  "Custom Words",
];

export {
  GameStateEnum,
  ErrorLevelEnum,
  AuthTypeEnum,
  ErrorTypeEnum,
  FiltersEnum,
  FilterTitles,
};

import React from "react";
import { GameContext } from "../../game/store";
import { numberWithCommas } from "../chatpanel/ScoreBoardUtils";

const max = 5;
const TopScoresPanel = () => {
  const gameContext = React.useContext(GameContext);

  const list = () =>
    gameContext.state.scoreList
      .filter((_, index) => index < max)
      .map((scoreObj, index) => (
        <span
          key={`top-${scoreObj.username}-${scoreObj.score}`}
          className="block break-all font-bold spawn"
          translate="no"
        >
          {index + 1}.{" "}
          <span
            style={{ color: scoreObj.colour }}
            className="font-bold"
            translate="no"
          >
            {scoreObj.username}:
          </span>{" "}
          <span className="text-yellow-600 break-normal">
            {numberWithCommas(scoreObj.score)}
          </span>
        </span>
      ));

  return (
    <div className="w-full flex flex-col items-center xl:items-start">
      <h2 className="font-bold text-3xl self-center">Top {max}</h2>

      {gameContext.state.scoreList.length ? (
        <div className="text-base 2xl:text-xl">{list()}</div>
      ) : (
        <span className="opacity-50 self-center text-xl">Nothing Yet</span>
      )}
    </div>
  );
};

export default TopScoresPanel;

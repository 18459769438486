import { Category } from "./categories.mjs";

export class DynamicCategory extends Category {
  /**
   *
   * @param name Name of category to display
   * @param translateTitle String "yes" or "no"
   * @param fetch Callback func when getting words. Must return a promise with a reslove value structure of  { words: Array } or a reject with { displayError: bool, msg: String }
   * @param filter type FiltersEnum, To be grouped with other categories.
   * @param authTypes Array of supported auth types. [] means all
   */
  constructor(name, translateTitle, fetch, filter, authTypes) {
    super(name, false, translateTitle, filter, null);
    this.fetch = fetch;
    this.authTypes = authTypes;
    this.isLoading = false;
  }

  onUserAuthed(user) {
    this.userData = user;
  }
}

import * as React from "react";
import { GameStateEnum } from "../../game/gameEnums.mjs";
import { GameContext } from "../../game/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

const PauseBtn = () => {
  const gameContext = React.useContext(GameContext);
  const [disabled, setDisabled] = React.useState(true);
  const [hasPaused, setHasPaused] = React.useState(false);

  React.useEffect(() => {
    if (
      gameContext.state.chatConnected &&
      gameContext.state.wordListPrimed &&
      (gameContext.state.gameState === GameStateEnum.Playing ||
        gameContext.state.gameState === GameStateEnum.Paused)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    if (gameContext.state.gameState === GameStateEnum.Paused) {
      setHasPaused(true);
    } else {
      setHasPaused(false);
    }
  }, [
    gameContext.state.gameState,
    gameContext.state.wordListPrimed,
    gameContext.state.chatConnected,
  ]);

  return React.useMemo(() => {
    return (
      <>
        <button
          disabled={disabled}
          aria-label="Pause Round"
          className={`${disabled && "cursor-default"} ${
            disabled ? "opacity-10 btn-panel--disabled " : "btn-panel--enable "
          }  ${
            hasPaused ? "bg-gray-700" : "bg-gray-700"
          }  py-1 px-3 text-xl font-bold border-2 border-white transition-opacity duration-300`}
          onClick={() => {
            if (hasPaused) {
              gameContext.dispatch({ type: "Unpause" });
            } else {
              gameContext.dispatch({ type: "Pause" });
            }
          }}
        >
          {hasPaused ? (
            <FontAwesomeIcon icon={faPlay} size="sm" />
          ) : (
            <FontAwesomeIcon icon={faPause} size="sm" />
          )}
        </button>
      </>
    );
  }, [hasPaused, disabled]);
};

export default PauseBtn;

import * as React from "react";
import { GameContext } from "../../../game/store";
import { ErrorLevelEnum } from "../../../game/gameEnums.mjs";

const DebugMenu = () => {
  const gameContext = React.useContext(GameContext);
  return (
    <div className="bg-black p-1 w-full">
      <h1 className="underline">Debug Context Menu</h1>
      <div className="flex gap-3">
        <div>
          <button
            className="p-1 border block"
            onClick={() => {
              gameContext.dispatch({ type: "Won" });
            }}
          >
            Won
          </button>
          <button
            className="p-1 border block"
            onClick={() => {
              gameContext.dispatch({ type: "Lost" });
            }}
          >
            Lost
          </button>
          <button
            className="border block"
            onClick={() => {
              console.log(gameContext.state);
            }}
          >
            Console State
          </button>
        </div>

        <div>
          <button
            className="border p-2 block"
            onClick={() => {
              gameContext.dispatch({
                type: "DisplayError",
                payload: {
                  msg: "Error Test message ERROR",
                  errorLevel: ErrorLevelEnum.ERROR,
                },
              });
            }}
          >
            Show Error Dialog {"(ERROR)"}
          </button>
          <button
            className="border p-2 block"
            onClick={() => {
              gameContext.dispatch({
                type: "DisplayError",
                payload: {
                  msg: "Error Test message WARN",
                  errorLevel: ErrorLevelEnum.WARN,
                },
              });
            }}
          >
            Show Error Dialog {"(WARN)"}
          </button>
          <button
            className="border p-2 block"
            onClick={() => {
              gameContext.dispatch({
                type: "DisplayError",
                payload: {
                  msg: "Error Test message INFO",
                  errorLevel: ErrorLevelEnum.INFO,
                },
              });
            }}
          >
            Show Error Dialog {"(INFO)"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DebugMenu;

import * as React from "react";
import PropTypes from "prop-types";
import Switch from "../../utils/Switch";
import Button from "../../utils/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../utils/Spinner";
import { DataLayerPush } from "../../../utils/browser_utils";

const CategorySwitch = ({
  label,
  isChecked,
  onChangeSwitch,
  isLoading,
  hasPanel = false,
  onClickPanel,
}) => {
  const onSwitchChange = (value) => {
    DataLayerPush("category_change", {
      category_name: label,
      category_checked: value,
    });
    onChangeSwitch(value);
  };

  const onShowPanelClick = () => {
    if (!isChecked) {
      DataLayerPush("category_change", {
        category_name: label,
        category_checked: true,
      });
    }
    onClickPanel(null);
  };

  const labelDisplay = () => {
    return <div className="select-none text-left pr-1">{label}</div>;
  };

  return (
    <div
      role="button"
      aria-label={`Flip Category Switch for ${label}`}
      aria-pressed={isChecked}
      onClick={(e) => {
        onSwitchChange(!isChecked);
        e.preventDefault();
        e.stopPropagation();
      }}
      className="flex justify-between items-center font-semibold hover:font-bold py-3 px-5  cursor-pointer  hover:bg-DTC_Yellow hover:text-black  h-full w-full"
    >
      {labelDisplay()}
      <div className="flex items-center">
        <div className={`${!isLoading && "hidden"}`}>
          <Spinner size="20px" />
        </div>

        <div
          className={`flex ${
            isLoading && "hidden"
          } items-center justify-center`}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Switch
              label={label}
              isLoading={isLoading}
              onChange={() => {
                onSwitchChange(!isChecked);
              }}
              isChecked={isChecked}
            />
          </div>

          {hasPanel && (
            <div className="ml-2">
              <Button
                OnClick={(e) => {
                  onShowPanelClick();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon
                  icon={faCog}
                  style={{ fontSize: "24px" }}
                  className="text-white hover:text-black"
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CategorySwitch.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChangeSwitch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hasPanel: PropTypes.bool,
  onClickPanel: PropTypes.func,
};

export default CategorySwitch;

import * as React from "react";
import { GameContext } from "../../game/store";
import { GameStateEnum } from "../../game/gameEnums";

const RevealBtn = () => {
  const gameContext = React.useContext(GameContext);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (
      gameContext.state.gameState === GameStateEnum.Playing ||
      gameContext.state.gameState === GameStateEnum.Paused
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [gameContext.state.gameState]);

  return (
    <button
      disabled={disabled}
      aria-label={"Reveal Word"}
      className={`${disabled && "cursor-default"} ${
        disabled
          ? "opacity-10 btn-panel--disabled"
          : "btn-panel--enable hover:bg-red-900"
      } bg-red-800 font-bold py-1 px-5 transition-opacity duration-300         
      border-2 border-white btn-panel`}
      onClick={() => {
        gameContext.dispatch({ type: "Lost" });
      }}
    >
      Reveal
    </button>
  );
};

export default RevealBtn;

import * as React from "react";
import PropTypes from "prop-types";
import { GameContext } from "../../game/store";

const ImageView = ({ showWord, height = 130, wordObj = null }) => {
  const gameContext = React.useContext(GameContext);
  const [showImage, setShowImage] = React.useState(false);
  const [width, setWidth] = React.useState("");
  const wordInPlay = React.useRef();
  wordInPlay.current = gameContext.state.wordInPlay;

  const ShowImage = (e) => {
    //Set image to a consistent height
    var aspectRatio = e.target.width / e.target.height;
    var new_width = height * aspectRatio;
    setWidth(new_width.toString());
    setShowImage(true);
  };

  const HideImage = () => {
    setShowImage(false);
  };

  const LoadCheck = (w, image, e) => {
    if (w.imageURL === image.src || wordObj) {
      if (w.height && w.width) {
        if (w.height == e.target.height && w.width == e.target.width) {
          ShowImage(e);
        } else {
          console.warn(
            `Picture of ${w.word} failed the dimension check with w ${e.target.width} x h ${e.target.height}`
          );
        }
      } else {
        ShowImage(e);
      }
    }
  };

  const LoadImage = (url) => {
    let image = new Image();
    image.onload = (e) => {
      //Show only if the image is still in wordInPlay and dimensions matches, if there is dimensions and theres no wordObj.
      if (!wordObj) {
        LoadCheck(wordInPlay.current, image, e);
      } else {
        LoadCheck(wordObj, image, e);
      }
    };
    image.onerror = () => {
      console.warn(
        `Picture of ${gameContext.state.wordInPlay.word} could be not be loaded`
      );
    };
    image.src = url;
  };

  //pre load image
  React.useEffect(() => {
    if (wordObj) {
      if (wordObj.imageURL) {
        LoadImage(wordObj.imageURL);
      }
    }
  }, []);
  React.useEffect(() => {
    if (!wordObj) {
      HideImage();
      if (
        gameContext.state.wordInPlay.word != "" &&
        gameContext.state.wordInPlay.imageURL != ""
      ) {
        LoadImage(gameContext.state.wordInPlay.imageURL);
      }
    }
  }, [gameContext.state.wordInPlay.imageURL]);

  return React.useMemo(() => {
    return (
      <div className="max-w-full mx-auto">
        {showWord && showImage ? (
          wordObj ? (
            <img
              src={wordObj.imageURL}
              height={height}
              width={width}
              key={wordObj.word}
            />
          ) : (
            <img
              src={gameContext.state.wordInPlay.imageURL}
              height={height}
              width={width}
              key={gameContext.state.wordInPlay.imageURL}
              className="inline"
            />
          )
        ) : (
          <div style={{ height: `${height}px` }}></div>
        )}
      </div>
    );
  }, [showImage, showWord]);
};

ImageView.propTypes = {
  showWord: PropTypes.bool,
  height: PropTypes.number,
  wordObj: PropTypes.object,
};

export default ImageView;

import { FiltersEnum } from "../gameEnums.mjs";
import categories_json from "./categories.json";

class Category {
  constructor(name, isOn, translateTitle, filter, words) {
    this.name = name;
    this.isOn = isOn;
    this.translateTitle = translateTitle;
    if (filter) {
      this.filter = filter;
    } else {
      this.filter = FiltersEnum.All;
    }

    this.words = words;
  }
}
let categories = [];

categories_json.forEach((catObj) => {
  categories.push(
    new Category(
      catObj.name,
      catObj.isOn,
      catObj.translateTitle,
      catObj.filterEnum,
      catObj.words
    )
  );
});

export { Category, categories };

import { client_id } from "../../api";
import { ErrorLevelEnum } from "../gameEnums.mjs";
import axios from "axios";

const getBTTVEmotes = (userData, otherChannelId) => {
  let userID = userData.id;
  if (otherChannelId) {
    userID = otherChannelId;
  }
  return new Promise((reslove, reject) => {
    const a = axios.create();
    a({
      method: "get",
      url: "https://api.betterttv.net/3/cached/users/twitch/" + userID,
    })
      .then((res) => {
        // console.log(res);
        let processedWord = [];

        let emotes = res.data.channelEmotes.concat(res.data.sharedEmotes);
        emotes.map((e) => {
          processedWord.push({
            word: e.code,
            imageURL: `https://cdn.betterttv.net/emote/${e.id}/3x`,
          });
        });
        if (processedWord.length != 0) {
          reslove({ words: processedWord });
        } else {
          reject({
            displayError: true,
            msg: "No BTTV Emotes Found.",
            errorLevel: ErrorLevelEnum.INFO,
          });
        }
      })
      .catch(() => {
        reject({
          displayError: true,
          msg: "Could not get BTTV emotes.",
          errorLevel: ErrorLevelEnum.WARN,
        });
      });
  });
};

const getFFZEmotes = (userData, otherChannelId) => {
  let userID = userData.id;
  if (otherChannelId) {
    userID = otherChannelId;
  }
  return new Promise((reslove, reject) => {
    const a = axios.create();
    a({
      method: "get",
      url:
        "https://api.betterttv.net/3/cached/frankerfacez/users/twitch/" +
        userID,
    })
      .then((res) => {
        let processedWord = [];

        res.data.map((e) => {
          let image = "";
          if (e.images["4x"] != null) {
            image = e.images["4x"];
          } else if (e.images["2x"] != null) {
            image = e.images["2x"];
          } else if (e.images["1x"] != null) {
            image = e.images["1x"];
          }
          processedWord.push({
            word: e.code,
            imageURL: image,
          });
        });
        if (processedWord.length != 0) {
          reslove({ words: processedWord });
        } else {
          reject({
            displayError: true,
            msg: "No FFZ Emotes Found.",
            errorLevel: ErrorLevelEnum.INFO,
          });
        }
      })
      .catch(() => {
        reject({
          displayError: true,
          msg: "Could not get FFZ emotes.",
          errorLevel: ErrorLevelEnum.WARN,
        });
      });
  });
};

const getSubEmotes = (userData, otherChannelId) => {
  let userID = userData.id;
  if (otherChannelId) {
    userID = otherChannelId;
  }
  return new Promise((reslove, reject) => {
    const a = axios.create();
    a({
      method: "get",
      url: "https://api.twitch.tv/helix/chat/emotes?broadcaster_id=" + userID,
      headers: {
        "Client-Id": client_id,
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((res) => {
        // console.log(res);
        let processedWord = [];
        res.data.data.map((e) => {
          let image = "";
          if (e.images["url_4x"] != null) {
            image = e.images["url_4x"];
          } else if (e.images["url_2x"] != null) {
            image = e.images["url_2x"];
          } else if (e.images["url_1x"] != null) {
            image = e.images["url_1x"];
          }
          processedWord.push({
            word: e.name,
            imageURL: image,
          });
        });
        if (processedWord.length != 0) {
          reslove({ words: processedWord });
        } else {
          reject({
            displayError: true,
            msg: "No Subscription Emotes Found.",
            errorLevel: ErrorLevelEnum.INFO,
          });
        }
      })
      .catch(() => {
        reject({
          displayError: true,
          msg: "Could not get subscription emotes.",
          errorLevel: ErrorLevelEnum.WARN,
        });
      });
  });
};

const get7TVEmotes = (userData, otherChannelId) => {
  let userID = userData.id;
  if (otherChannelId) {
    userID = otherChannelId;
  }
  return new Promise((reslove, reject) => {
    const a = axios.create();
    a({
      method: "get",
      url: `https://7tv.io/v3/users/twitch/${userID}`,
    })
      .then((res) => {
        // console.log(res);

        let processedWord = res.data.emote_set.emotes.map((e) => {
          return {
            word: e.name,
            imageURL: `https://cdn.7tv.app/emote/${e.id}/4x.webp`,
          };
        });

        if (processedWord && processedWord.length != 0) {
          reslove({ words: processedWord });
        } else {
          reject({
            displayError: true,
            msg: "No 7TV Emotes Found",
            errorLevel: ErrorLevelEnum.INFO,
          });
        }
      })
      .catch(() => {
        reject({
          displayError: true,
          msg: "Could not get 7TV emotes.",
          errorLevel: ErrorLevelEnum.WARN,
        });
      });
  });
};

export { getBTTVEmotes, getFFZEmotes, getSubEmotes, get7TVEmotes };

import * as React from "react";
import { GameContext } from "../../game/store";
import { GameStateEnum } from "../../game/gameEnums";

import countdownSound from "../../sounds/countdown.mp3";

const Timer = () => {
  const gameContext = React.useContext(GameContext);
  const [seconds, setSeconds] = React.useState(
    gameContext.state.settings.timeDurationSecs
  );
  const [displaySecs, setDisplaySecs] = React.useState("0");
  const [displayMin, setDisplayMin] = React.useState("");
  const secondsRef = React.useRef();
  const tickerRef = React.useRef();

  secondsRef.current = seconds;

  React.useEffect(() => {
    setSeconds(gameContext.state.settings.timeDurationSecs);
  }, []);

  React.useEffect(() => {
    if (gameContext.state.gameState === GameStateEnum.Playing) {
      Start();
    }
    if (gameContext.state.gameState !== GameStateEnum.Playing) {
      Stop();
    }
    return () => Stop();
  }, [gameContext.state.gameState]);

  const Start = () => {
    tickerRef.current = setInterval(Tick, 1000);
  };

  React.useEffect(() => {
    let newDisplaySecs = parseInt(seconds % 60, 10);
    let newDisplayMin = "";
    if (seconds >= 60) {
      newDisplayMin = parseInt(seconds / 60, 10).toString() + ":";
      newDisplaySecs =
        newDisplaySecs < 10 ? "0" + newDisplaySecs : newDisplaySecs;
    }

    //play ending sound
    if (
      seconds <= gameContext.state.settings.timerWarnThreshold &&
      seconds != 0
    ) {
      const sfx = new Audio(countdownSound);
      sfx.volume = gameContext.state.settings.volume;
      sfx.play();
    }

    if (seconds == 0) {
      gameContext.dispatch({ type: "Lost" });
    }

    setDisplaySecs(newDisplaySecs);
    setDisplayMin(newDisplayMin);

    gameContext.dispatch({
      type: "SecondsTick",
      payload: { seconds: seconds },
    });
  }, [seconds]);

  const Tick = () => {
    setSeconds(secondsRef.current - 1);
  };

  const Stop = () => {
    clearInterval(tickerRef.current);
  };

  return (
    <div>
      {gameContext.state.gameState >= GameStateEnum.RoundStart && (
        <span
          className={` transition-colors duration-300
          ${
            seconds <= gameContext.state.settings.timerWarnThreshold &&
            gameContext.state.gameState === GameStateEnum.Playing
              ? "text-red-400"
              : ""
          }
          ${
            gameContext.state.gameState === GameStateEnum.Lost && "text-red-600"
          }
          ${
            gameContext.state.gameState === GameStateEnum.Won &&
            "text-DTC_WinColor"
          } ${
            gameContext.state.gameState === GameStateEnum.Paused &&
            "text-gray-500"
          } text-5xl font-bold`}
        >
          <span translate="no">{displayMin}</span>
          <span translate="no">{displaySecs}</span>
        </span>
      )}
    </div>
  );
};

export default Timer;

import * as React from "react";
import useWindowDimensions from "./useWindowDimensions";
import { GameContext } from "../../game/store";

const md_breakpoint = "768";
function useShowPanel(isPanel = false) {
  const gameContext = React.useContext(GameContext);
  const { width: window_width } = useWindowDimensions();

  return (
    (window_width > md_breakpoint && gameContext.state.showSidePanel) ||
    (isPanel && window_width < md_breakpoint)
  );
}

export default useShowPanel;
